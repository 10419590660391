// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";  // Importa la función para autenticación
import { getAnalytics } from "firebase/analytics";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCk9PyucyXaBLU3rtPHHdAuwqszjoHYtfs",
  authDomain: "lalomita-be97c.firebaseapp.com",
  projectId: "lalomita-be97c",
  storageBucket: "lalomita-be97c.appspot.com",
  messagingSenderId: "14342360267",
  appId: "1:14342360267:web:e3e8c0d0fa77fcbdf61f4e",
  measurementId: "G-T4834QS9SN"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Inicializa el servicio de autenticación
const analytics = getAnalytics(app);  // Inicializa Analytics

export { auth };  // Exporta el objeto de autenticación
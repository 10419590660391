import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';

// Definir una animación de parpadeo
const blink = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;

// Componente Skeleton animado
const AnimatedSkeleton = styled(Skeleton)(({ theme }) => ({
  animation: `${blink} 1.5s ease-in-out infinite`,
}));

const StyledIframe = styled('iframe')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'opacity 0.5s ease, transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[6],
  },
}));

export default function LocationMap() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);

  // Simular carga
  React.useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000); // Cambia el tiempo según el caso real
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container
      id="location"
      sx={{
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box sx={{ px: { xs: 2, sm: 4 }, py: 4,  }}> {/* Padding horizontal y vertical para mejor espaciado */}
        <Typography
          component="h2"
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: { xs: 2, sm: 3, md: 4 }, // Incremento del margen inferior para mejor separación
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.25rem' }, // Ajuste de tamaño de fuente
            lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 }, // Ajuste de interlineado para mejor legibilidad
            wordBreak: 'break-word', // Permite el ajuste de palabras largas
          }}
        >
          Nuestra ubicación
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            mb: { xs: 2, sm: 4 }, // Margen inferior para separar del contenido superior
            textAlign: 'center',
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Ajuste del tamaño de fuente
            lineHeight: { xs: 1.5, sm: 1.6, md: 1.7 }, // Ajuste de interlineado para mejorar la lectura
            wordBreak: 'break-word', // Ajuste de palabras largas
          }}
        >
          Ubicados en Temperley, nuestra ubicación ofrece fácil acceso a todos los puntos de interés en la zona.
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: 1200,
          height: '450px',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: theme.shape.borderRadius,
          border: `1px solid ${theme.palette.divider}`,
          '& iframe': {
            width: '100%',
            height: '100%',
            border: 'none',
            backgroundColor: theme.palette.background.default,
            transition: 'opacity 0.5s ease',
          },
        }}
      >
        {loading ? (
          <AnimatedSkeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <StyledIframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6012.7210458911!2d-58.34891348294242!3d-34.74401699959399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32d1bc9f79d4f%3A0xd93e739c74d1f802!2sESPACI%C3%93%20LA%20LOMITA!5e0!3m2!1ses-419!2sar!4v1722379448742!5m2!1ses-419!2sar"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{ opacity: loading ? 0 : 1 }}
          />
        )}
      </Box>
    </Container>
  );
}
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import LaLomitaIcon from './LaLomitaIcon';
import LaLomitaIconJusta from './LaLomitaIconJusta';
import LaLomitaLogoJusto from './LaLomitaLogoJusto';
import PalmeraLogo from './PalmeraLogo';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';
import { Typography } from '@mui/material';

const CustomMenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  margin: theme.spacing(0, 1),
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 0,
  borderRadius: 30,
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  transition: 'background 0.3s, transform 0.3s',
  '&:hover': {
    background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
    transform: 'scale(1.05)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 4px rgba(33, 203, 243, .5)',
  },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const handleNavigation = (sectionId) => {
    navigate('/');
    setTimeout(() => scrollToSection(sectionId), 300);
  };

  return (
    <AppBar
      position="fixed"
      id="navbar"
      sx={(theme) => ({
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
        borderColor: 'divider',
        transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
      })}
    >
      <Container
        maxWidth="lg" // Mantén el ancho máximo en pantallas grandes
        sx={{
          //display: 'flex',
          justifyContent: { xs: 'flex-end', lg: 'center' }, // Alinea a la derecha en xs y al centro en sm+
        }}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // Alinea a la derecha en xs
            width: { xs: 'auto', lg: '100%' }, // Ajusta el ancho en xs
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            bgcolor: 'hsla(220, 60%, 99%, 0.9)', // Color de fondo con opacidad por defecto
            boxShadow: theme.shadows[2],
            transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
            ...theme.applyStyles('dark', {
              bgcolor: 'hsla(220, 0%, 0%, 0.7)', // Fondo en modo oscuro
              boxShadow: theme.shadows[4],
            }),
          })}
        >
          <LaLomitaIconJusta
            sx={{ width: { xs: '90px' }, ml: 1, height: '50px' }}
          />
          {/* Menú visible solo en pantallas grandes */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
            <CustomMenuButton onClick={() => handleNavigation('home')}>
              Inicio
            </CustomMenuButton>
            <CustomMenuButton onClick={() => handleNavigation('features')}>
              Características
            </CustomMenuButton>
            <CustomMenuButton onClick={() => handleNavigation('highlights')}>
              Comodidades
            </CustomMenuButton>
            <CustomMenuButton onClick={() => handleNavigation('location')}>
              Ubicación
            </CustomMenuButton>
            <CustomMenuButton onClick={() => handleNavigation('pricing')}>
              Precios
            </CustomMenuButton>
            <CustomMenuButton onClick={() => handleNavigation('faq')}>
              Ayuda
            </CustomMenuButton>
          </Box>
          {/* Menú visible solo en pantallas pequeñas */}
          <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Alinea el botón a la derecha
                    p: 2, // Padding alrededor del contenedor
                  }}
                >
                  <LaLomitaIconJusta
                    sx={{ width: { xs: '90px' }, height: '50px' }}
                  />
                  <IconButton
                    onClick={toggleDrawer(false)}
                    aria-label="Cerrar menú" // Texto alternativo para accesibilidad
                  >
                    <CloseRoundedIcon sx={{ fontSize: 24 }} /> {/* Tamaño del ícono ajustado */}
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => handleNavigation('home')}>
                  Inicio
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('features')}>
                  Características
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('highlights')}>
                  Comodidades
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('location')}>
                  Ubicación
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('pricing')}>
                  Precios
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('faq')}>
                  Ayuda
                </MenuItem>
                <MenuItem onClick={() => navigate('/admin')}>
                  Configuración
                </MenuItem>
                <Divider sx={{ my: 3 }} />
                <MenuItem
                  sx={{
                    px: 2, // Padding horizontal para ajustar la separación dentro del MenuItem
                    py: 1, // Padding vertical para mayor espacio
                    '&:hover': {
                      backgroundColor: 'background.default', // Cambia el color de fondo en hover
                    },
                  }}
                >
                  {user ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start', // Alinea los elementos al inicio en el eje vertical
                        justifyContent: 'flex-start', // Alinea los elementos al inicio en el eje horizontal
                        gap: 2, // Espacio entre los botones
                        width: '100%',
                        maxWidth: 360, // Ajusta el ancho máximo
                        mx: 'auto', // Centra horizontalmente el contenedor
                      }}
                    >
                      <CustomButton
                        color="primary"
                        variant="contained"
                        fullWidth // Se ajusta al contenedor
                        onClick={handleSignOut}
                        startIcon={<ExitToAppIcon />}
                        sx={{
                          py: 1, // Padding vertical
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sombra suave
                          transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Transición
                          '&:hover': {
                            backgroundColor: 'primary.dark', // Color en hover
                            boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)', // Sombra en hover
                          },
                        }}
                      >
                        Cerrar Sesión
                      </CustomButton>
                    </Box>
                  ) : (
                    <CustomButton
                      component="a"
                      href="/login"
                      fullWidth // Se ajusta al contenedor
                      startIcon={<LoginIcon />}
                      sx={{
                        py: 1, // Padding vertical
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sombra suave
                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Transición
                        '&:hover': {
                          backgroundColor: 'primary.light', // Color en hover
                          boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)', // Sombra en hover
                        },
                      }}
                    >
                      Iniciar Sesión
                    </CustomButton>
                  )}
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: 'none', lg: 'flex' }, gap: 0.5, alignItems: 'center' }}>
            {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
            {user ? (
              <>
                <IconButtonStyled
                  color="primary"
                  onClick={() => navigate('/admin')}
                  size="small"
                >
                  <SettingsIcon />
                </IconButtonStyled>
                <CustomButton
                  size="small"
                  onClick={handleSignOut}
                  startIcon={<ExitToAppIcon />}
                >
                  Cerrar Sesión
                </CustomButton>
              </>
            ) : (
              <CustomButton
                size="small"
                component="a"
                href="/login"
                startIcon={<LoginIcon />}
              >
                Iniciar Sesión
              </CustomButton>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
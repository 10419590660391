import React from 'react';
import { Card, CardContent, CardActions, Box, Typography, Divider, Chip, Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function WeekendTierCard({ tier, handleContactButtonClick }) {
  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        border: 'none',
        backgroundColor: 'background.paper',
        boxShadow: 3,
        background: 'radial-gradient(circle at 50% 0%, hsl(210, 98%, 35%), hsl(210, 100%, 16%))',
        boxShadow: `0 8px 12px hsla(210, 98%, 42%, 0.2)`,
        borderRadius: 2,
        maxWidth: 360,
        margin: 'auto',
        '@media (max-width:600px)': {
          maxWidth: '100%',
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            color: 'grey.100',
          }}
        >
          <Typography component="h3" variant="h6">
            {tier.title}
          </Typography>
          <Chip
            icon={<AutoAwesomeIcon />}
            label={tier.subheader}
            size="small"
            sx={{
              borderColor: 'hsla(220, 60%, 99%, 0.3)',
              backgroundColor: 'hsla(220, 60%, 99%, 0.1)',
              '& .MuiChip-label': {
                color: 'hsl(0, 0%, 100%)',
              },
              '& .MuiChip-icon': {
                color: 'primary.light',
              },
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'baseline', color: 'grey.50', mb: 2 }}>
          <Typography component="h3" variant="h4">
            ${tier.price}
          </Typography>
          <Typography component="h3" variant="body1" sx={{ ml: 1 }}>
            por día
          </Typography>
        </Box>
        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
        {tier.description.map((line) => (
          <Box key={line} sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
            <CheckCircleRoundedIcon sx={{ width: 20, color: 'primary.light' }} />
            <Typography variant="subtitle2" component="span" sx={{ color: 'grey.50' }}>
              {line}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
            height: '150px',
            borderRadius: 2,
            backgroundColor: 'background.paper',
            background: 'radial-gradient(circle at 50% 0%, hsl(210, 98%, 35%), hsla(210, 98%, 42%, 0.2))',
            boxShadow: `0 8px 12px hsl(210, 100%, 16%)`,
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CalendarMonthIcon sx={{ color: 'primary.light', fontSize: 24 }} />
            <Typography variant="subtitle2" component="span" sx={{ color: 'grey.50' }}>
              Precio aplicable en:
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
            {tier.month.map((month) => (
              <Chip
                key={month}
                label={month}
                size="small"
                color="primary"
                sx={{
                  borderRadius: 16,
                  color: 'grey.50',
                  border: '1px solid',
                  borderColor: 'primary.main',
                }}
              />
            ))}
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button fullWidth variant="contained" onClick={handleContactButtonClick}>
          Contáctanos
        </Button>
      </CardActions>
    </Card>
  );
}

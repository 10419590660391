import React, { useState, useEffect } from 'react';
import { Container, Skeleton } from '@mui/material';
import MainComponent from './Pricing/MainComponent';
import axios from 'axios';

const promotions = [
  {
    title: 'Horario diurno',
    month: [
      'Septiembre',
      'Octubre',
    ],
    tiempo: 'por 4 horas',
    description: [
      'Precios hasta 20 personas por 4 horas de servicio. Excedentes de personas deben abonar $5.000 c/u.',
      'Se puede solicitar extension horaria: $30.000 cada hora.',
      'Se toman las reservas luego de abonar el 50% del total a pagar.',
      'Se debe abonar un deposito de $50.000 por supuestos daños y/o faltantes, el cual se reintegrara 24hs luego de finalizado el evento y supervisado el espacio.',
    ],
    subcategories: [
      {
        days: 'De lunes a jueves',
        price: '100.000',
      },
      {
        days: 'De viernes a domingo',
        price: '130.000',
      }
    ],
  },
  {
    title: 'Horario nocturno',
    price: '150.000',
    month: [
      'Septiembre',
      'Octubre',
    ],
    tiempo: 'de 19hs a 23hs',
    description: [
      'Precios hasta 20 personas en el horario de 19hs a 23hs. Excedentes de personas deben abonar $10.000 c/u.',
      'Se puede solicitar extension horaria: $40.000 cada hora.',
      'Se toman las reservas luego de abonar el 50% del total a pagar.',
      'Se debe abonar un deposito de $50.000 por supuestos daños y/o faltantes, el cual se reintegrara 24hs luego de finalizado el evento y supervisado el espacio.',
    ],
    subcategories: [
      {
        days: 'De domingo a jueves',
        price: '150.000',
      },
      {
        days: 'Viernes y sábado',
        price: '250.000',
      }
    ],
  },
];

export default function Pricing() {
  const [tiers, setTiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://api.espaciolalomita.com/api/pricings')
      .then((response) => {
        const data = response.data;
        setTiers(data);
        setShowSkeleton(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching pricing data:', error);
        setError(error.message);
        setShowSkeleton(false);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        {showSkeleton && (
          <>
            <Skeleton variant="text" width={200} height={40} />
            <Skeleton variant="rectangular" width="100%" height={150} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ my: 2 }} />
            <Skeleton variant="rectangular" width="100%" height={150} />
          </>
        )}
      </Container>
    );
  }

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <MainComponent tiers={tiers} promotions={promotions} loading={loading}  errorPricing={error}/>
    </Container>
  );
}
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig'; // Ajusta la ruta según sea necesario
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ProtectedRoute = () => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    // Contenedor del spinner de carga con diseño mejorado
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'background.default', // Ajusta según el tema de tu aplicación
          zIndex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }}
      >
        <CircularProgress sx={{ mb: 2 }} />
        <Typography variant="h6" color="text.primary">
          Cargando, por favor espere...
        </Typography>
      </Box>
    );
  }

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;

import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [showSkeleton, setShowSkeleton] = React.useState(true);
  const [faqs, setFaqs] = React.useState([]);
  const [error, setError] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    // Fetch FAQs from API
    axios.get('https://api.espaciolalomita.com/api/faqs')
      .then((response) => {
        setFaqs(response.data);
        setShowSkeleton(false);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        console.error('Error fetching FAQs:', error);
        setShowSkeleton(false);
        setLoading(false);
      });
  }, []);

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      {showSkeleton ? (
        <>
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="rectangular" width="100%" height={150} />
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ my: 2 }} />
          <Skeleton variant="rectangular" width="100%" height={150} />
        </>
      ) : (
        <>
          <Box sx={{ px: { xs: 2, sm: 4 }, py: 4,  }}> {/* Padding horizontal y vertical para mejor espaciado */}
            <Typography
              component="h2"
              variant="h4"
              sx={{
                fontWeight: 'bold',
                mb: { xs: 2, sm: 3, md: 4 }, // Incremento del margen inferior para mejor separación
                textAlign: 'center',
                fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.25rem' }, // Ajuste de tamaño de fuente
                lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 }, // Ajuste de interlineado para mejor legibilidad
                wordBreak: 'break-word', // Permite el ajuste de palabras largas
              }}
            >
              Preguntas Frecuentes
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                mb: { xs: 2, sm: 4 }, // Margen inferior para separar del contenido superior
                textAlign: 'center',
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Ajuste del tamaño de fuente
                lineHeight: { xs: 1.5, sm: 1.6, md: 1.7 }, // Ajuste de interlineado para mejorar la lectura
                wordBreak: 'break-word', // Ajuste de palabras largas
              }}
            >
              Si tienes alguna pregunta que no esté aquí, no dudes en ponerte en contacto para obtener información adicional.
            </Typography>
          </Box>
          {error ? (
            <Container
              id="faq"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
                <ErrorOutlineIcon color="error" fontSize="large" />
                <Typography variant="h5" color="error">
                  Ha ocurrido un error
                </Typography>
              </Stack>
              <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                {error}
              </Typography>
            </Container>
          ) : (
            <Box sx={{ width: '100%' }}>
              {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography component="h3" variant="subtitle2">
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                    >
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </>
      )}
    </Container>
  );
}
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  TableSortLabel,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Chip,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormHelperText
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { CheckCircle, Cancel, Error } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

const StepIcon = (props) => {
  const { active, completed } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: completed ? '#4caf50' : active ? '#1976d2' : '#b0bec5',
        color: 'white',
        fontSize: 20,
        transition: 'background-color 0.3s, transform 0.3s',
        transform: active ? 'scale(1)' : 'scale(1)',
      }}
    >
      {completed ? <CheckCircle /> : active ? <Error /> : <Cancel />}
    </div>
  );
};

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdminPricing = () => {
  const [prices, setPrices] = useState([]);
  const [filteredPrices, setFilteredPrices] = useState([]);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [orden, setOrden] = useState('');
  const [description, setDescription] = useState([]); // Mantener como array
  const [descriptionInput, setDescriptionInput] = useState(''); // Para controlar el input de la descripción
  const [subheader, setSubheader] = useState('');
  const [active, setActive] = useState(true);
  const [highlight, setHighlight] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '', severity: '' });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({ title: false, price: false, description: false, month: false, orden: false });
  const [month, setMonth] = React.useState([]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [editingChip, setEditingChip] = useState(false);
  const [editingChipIndex, setEditingChipIndex] = useState(null);
  const steps = ['Datos del Precio', 'Confirmar Datos'];

  const handleMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonth(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  useEffect(() => {
    fetch('https://api.espaciolalomita.com/api/pricings')
      .then(response => response.ok ? response.json() : Promise.reject(response))
      .then(data => {
        setPrices(data);
        setFilteredPrices(data);
      })
      .catch(error => {
        console.error('Error fetching prices:', error);
        setNotification({ open: true, message: 'Error al obtener los precios.', severity: 'error' });
      });
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredPrices(prices);
    } else {
      setFilteredPrices(prices.filter(p =>
        p.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        p.description.some(d => d.toLowerCase().includes(searchTerm.toLowerCase()))
      ));
    }
  }, [searchTerm, prices]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCancel = () => {
    clearForm();
    setActiveStep(0);
  };

  const handleChangeOrder = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setOrden(value);

    // Validación: Mostrar error si el valor está vacío o no es un número entero
    if (value === '' || isNaN(value)) {
      setError(true);
      setHelperText('Ingrese un número entero válido.');
    } else {
      setError(false);
      setHelperText('');
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validateFields = () => {
    const newErrors = {
      title: !title.trim(),
      price: !price.trim(),
      description: description.length === 0,
      month: month.length === 0,
      orden: !/^\d+$/.test(orden), // Verifica que `orden` tenga al menos un dígito y solo contenga números
    };
    setErrors(newErrors);
    return !newErrors.title && !newErrors.price && !newErrors.description && !newErrors.month && !newErrors.orden;
  };  

  const handleAddDescription = () => {
    if (descriptionInput.trim() !== '') {
      if (editingChip) {
        const updatedDescriptions = [...description];
        updatedDescriptions[editingChipIndex] = descriptionInput.trim();
        setDescription(updatedDescriptions);
        setEditingChip(false); 
        setEditingChipIndex(null);
      } else {
        setDescription([...description, descriptionInput.trim()]);
      }
      setDescriptionInput('');
    }
  };

  const handleDeleteDescription = (index) => {
    setDescription(description.filter((_, i) => i !== index));
  };

  const handleChipClick = (index) => {
    setDescriptionInput(description[index]);
    setEditingChip(true);
    setEditingChipIndex(index);
  }

  const handleAddOrUpdatePricing = () => {
    if (!validateFields()) {
      setNotification({ open: true, message: 'Por favor, complete todos los campos obligatorios.', severity: 'warning' });
      return;
    }

    const url = editIndex !== null ? `https://api.espaciolalomita.com/api/pricings/${prices[editIndex]._id}` : 'https://api.espaciolalomita.com/api/pricings';
    const method = editIndex !== null ? 'PUT' : 'POST';

    const body = {
      title,
      price,
      description,
      month,
      subheader: highlight ? subheader : '',
      orden,
      active,
      highlight
    };

    if (highlight) {
      fetch('https://api.espaciolalomita.com/api/pricings/highlight', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ highlight: false })
      }).catch(error => {
        console.error('Error removing previous highlight:', error);
      });
    }    

    fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then(response => response.ok ? response.json() : Promise.reject(response))
      .then(data => {
        if (editIndex !== null) {
          setPrices(prices.map((pricing, index) => index === editIndex ? data : pricing));
          setEditIndex(null);
          setNotification({ open: true, message: 'Precio actualizado con éxito.', severity: 'success' });
        } else {
          setPrices([...prices, data]);
          setNotification({ open: true, message: 'Precio añadido con éxito.', severity: 'success' });
        }
        clearForm();
        setActiveStep(0);
      })
      .catch(error => {
        console.error('Error saving pricing:', error);
        setNotification({ open: true, message: 'Error al guardar el precio.', severity: 'error' });
      });
  };

  const handleEdit = (index) => {
    const pricing = paginatedPrices[index];
    const pricingIndex = prices.findIndex(p => p._id === pricing._id); // Encuentra el índice en el arreglo original
    setTitle(pricing.title);
    setPrice(pricing.price);
    setDescription(pricing.description);
    setMonth(pricing.month);
    setSubheader(pricing.subheader || '');
    setActive(pricing.active);
    setHighlight(pricing.highlight);
    setOrden(pricing.orden);
    setEditIndex(pricingIndex); // Usar el índice original para actualizaciones
    setActiveStep(0);
    if (editingChip) {
      setEditingChip(false); 
      setEditingChipIndex(null);
      setDescriptionInput('');
    }
  };
  
  const handleDelete = (index) => {
    const pricing = paginatedPrices[index]; 
    const pricingIndex = prices.findIndex(p => p._id === pricing._id); // Encuentra el índice en el arreglo original
    setDeleteIndex(pricingIndex); // Usar el índice original para eliminaciones
    setOpenConfirmDialog(true);
  };
  
  
  const confirmDelete = () => {
    const pricingId = prices[deleteIndex]._id;
  
    fetch(`https://api.espaciolalomita.com/api/pricings/${pricingId}`, {
      method: 'DELETE',
    })
      .then(response => response.ok ? response.json() : Promise.reject(response))
      .then(() => {
        setPrices(prices.filter((_, i) => i !== deleteIndex));
        setFilteredPrices(filteredPrices.filter((_, i) => i !== deleteIndex));
        setNotification({ open: true, message: 'Precio eliminado con éxito.', severity: 'success' });
        setOpenConfirmDialog(false);
      })
      .catch(error => {
        console.error('Error deleting pricing:', error);
        setNotification({ open: true, message: 'Error al eliminar el precio.', severity: 'error' });
        setOpenConfirmDialog(false);
      });
  };
  

  const handleCloseNotification = () => setNotification({ open: false, message: '', severity: '' });

  const clearForm = () => {
    setTitle('');
    setPrice('');
    setDescription([]);
    setMonth([]);
    setDescriptionInput('');
    setSubheader('');
    setOrden('');
    setActive(true);
    setHighlight(false);
    setEditIndex(null);
  };

  const handleNext = () => {
    if (activeStep === 0 && !validateFields()) {
      setNotification({ open: true, message: 'Por favor, complete todos los campos obligatorios.', severity: 'warning' });
      return;
    }
    setActiveStep(prevStep => prevStep + 1);
  };
  const handleBack = () => setActiveStep(prevStep => prevStep - 1);
  const handleReset = () => {
    clearForm();
    setActiveStep(0);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <TextField
              label="Título"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={errors.title}
              helperText={errors.title ? 'El título es obligatorio.' : ''}
              margin="normal"
              InputProps={{
                endAdornment: errors.title && (
                  <InputAdornment position="end">
                    <Error color="error" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Precio"
              variant="outlined"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value.replace(/[^0-9.]/g, ''))}
              error={errors.price}
              helperText={errors.price ? 'El precio es obligatorio.' : 'Ejemplo: 199.99'}
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: errors.price && (
                  <InputAdornment position="end">
                    <Error color="error" />
                  </InputAdornment>
                ),
                inputMode: 'decimal',
                placeholder: "Ingrese el precio en formato 0.00"
              }}
            />
            <TextField
              label="Orden"
              name="orden"
              id="orden"
              variant="outlined"
              fullWidth
              value={orden}
              onChange={handleChangeOrder}
              margin="normal"
              error={errors.orden}
              helperText={errors.orden ? 'El orden es obligatorio.' : 'Ejemplo: 1 (Aparecera primero)'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: '1.2rem' }}>#</span>
                  </InputAdornment>
                ),
                endAdornment: errors.orden && (
                  <InputAdornment position="end">
                    <Error color="error" />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '4px',
                  backgroundColor: '#f9f9f9',
                },
              }}
              placeholder="Ingrese el orden en que debe aparecer"
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Descripción"
                variant="outlined"
                fullWidth
                rows={3}
                multiline
                value={descriptionInput}
                error={errors.description}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setDescriptionInput(e.target.value);
                  }
                }}
                margin="normal"
                helperText={'La descripción es obligatoria.'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {descriptionInput && (
                        <>
                          <Tooltip title={editingChip ? 'Confirmar cambio' : 'Agregar descripción'} placement="top">
                            <IconButton
                              onClick={handleAddDescription}
                              aria-label={editingChip ? 'editar descripción' : 'agregar descripción'}
                              color={editingChip ? 'success' : 'primary'}
                              size="small"
                              sx={{
                                marginRight: 1, // Usando sx para margen
                                backgroundColor: '#e3f2fd', // Color de fondo sutil
                                borderRadius: '50%', // Hacer los iconos circulares
                                boxShadow: 3, // Sombra para resaltar el ícono
                                '&:hover': {
                                  backgroundColor: '#bbdefb', // Color al pasar el mouse
                                  boxShadow: 6, // Aumentar la sombra al pasar el mouse
                                },
                              }}
                            >
                              {editingChip ? <CheckCircle /> : <AddIcon />}
                            </IconButton>
                          </Tooltip>
                          {editingChip && (
                            <Tooltip title="Cancelar edición" placement="top">
                              <IconButton
                                aria-label="cancelar edición"
                                onClick={() => {
                                  setEditingChip(false);
                                  setDescriptionInput(''); // Limpiar el input
                                  setEditingChipIndex(null); // Restablecer el índice de edición
                                }}
                                color="error"
                                size="small"
                                sx={{
                                  backgroundColor: '#ffebee', // Color de fondo sutil
                                  borderRadius: '50%', // Hacer los iconos circulares
                                  boxShadow: 3, // Sombra para resaltar el ícono
                                  '&:hover': {
                                    backgroundColor: '#ffcdd2', // Color al pasar el mouse
                                    boxShadow: 6, // Aumentar la sombra al pasar el mouse
                                  },
                                }}
                              >
                                <Cancel />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </InputAdornment>

                  ),
                  placeholder: "Agrega una descripcion",
                }}
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: editingChip ? '#3f51b5' : undefined, // Borde de enfoque
                    },
                    '&:hover fieldset': {
                      borderColor: '#3f51b5', // Color del borde al pasar el mouse
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3f51b5', // Color del borde al estar enfocado
                    },
                  }
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {description.map((desc, index) => (
                <Chip
                  key={index}
                  label={desc}
                  onClick={() => handleChipClick(index)}
                  onDelete={() => handleDeleteDescription(index)}
                  sx={{
                    marginRight: 1,
                    marginBottom: 1,
                    backgroundColor: editingChipIndex === index ? '#e3f2fd' : 'default', // Color de fondo cuando está en edición
                    border: editingChipIndex === index ? '2px solid #1976d2' : 'none', // Borde resaltado cuando está en edición
                    '&:hover': {
                      backgroundColor: editingChipIndex === index ? '#bbdefb' : '#e3f2fd', // Color al pasar el mouse
                    },
                  }}
                />
              ))}
            </Box>
            <FormControl fullWidth margin="normal" error={Boolean(errors.month)}>
              <InputLabel id="month">Mes</InputLabel>
              <Select
                labelId="month"
                id="month"
                name="month"
                multiple
                value={month}
                onChange={handleMonthChange}
                input={<OutlinedInput label="Mes" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', maxWidth: '100%' }}>
                    {selected.join(', ')}
                  </Box>
                )}
                MenuProps={MenuProps}
                sx={{
                  borderRadius: 1,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.dark',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.dark',
                  },
                }}
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              {errors.month && (
                <FormHelperText>{'Seleccione al menos un mes.'}</FormHelperText>
              )}
            </FormControl>
            <Tooltip title={!highlight ? "Habilita   destacar para activar este campo" : ""}>
              <span>
                <TextField
                  label="Subtítulo"
                  variant="outlined"
                  fullWidth
                  value={subheader}
                  onChange={(e) => setSubheader(e.target.value)}
                  margin="normal"
                  disabled={!highlight}
                  placeholder={!highlight ? "Este campo está deshabilitado" : ""}
                  InputProps={{
                    style: {
                      backgroundColor: !highlight ? "#f5f5f5" : "white",
                      color: !highlight ? "#a9a9a9" : "black",
                    },
                  }}
                />
              </span>
            </Tooltip>
            <FormControlLabel
              control={
                <Checkbox
                  checked={highlight}
                  onChange={(e) => setHighlight(e.target.checked)}
                  color="primary"
                />
              }
              label="Destacar este precio"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  color="primary"
                />
              }
              label="Activo"
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Confirmar Datos</Typography>
            <Typography variant="body1"><strong>Título:</strong> {title}</Typography>
            <Typography variant="body1"><strong>Precio:</strong> ${price}</Typography>
            <Typography variant="body1"><strong>Orden:</strong> {orden}</Typography>
            <Typography variant="body1"><strong>Descripción:</strong> {description.length === 0 ? 'No tiene' : description.join(', ')}</Typography>
            <Typography variant="body1"><strong>Mes:</strong>  {month.join(', ')}</Typography>
            {highlight ? (
              <Typography variant="body1"><strong>Subtitulo:</strong> {subheader}</Typography>
            ) : (
              <Typography variant="body1"><strong>Subtitulo:</strong> No tiene</Typography>
            )}
            <Typography variant="body1"><strong>Estado:</strong> {active ? 'Activo' : 'Inactivo'}</Typography>
            <Typography variant="body1"><strong>Destacar:</strong> {highlight ? 'Sí' : 'No'}</Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  const sortedPrices = [...filteredPrices].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });

  const paginatedPrices = sortedPrices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box
    sx={{
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        pt: 8,
    }}
    >
      <Container
        sx={(theme) => ({
          pt: `${theme.spacing(16)}`, // Ajusta el padding superior según la altura de la AppBar
          pb: { xs: theme.spacing(4), sm: theme.spacing(8) },
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: theme.spacing(2), sm: theme.spacing(4) },
          borderRadius: 2,
        })}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: 'text.primary',
            width: { sm: '100%', md: '60%' },
            textAlign: 'center',
          }}
        >
          Gestión de Precios
        </Typography>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ 
            '& .MuiStepIcon-root': { transition: 'transform 0.3s' },
            '& .MuiStepLabel-root': { 
              '&.Mui-active': { color: '#1976d2' },
              '&.Mui-completed': { color: '#4caf50' }
            }
          }}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={StepIcon}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Precio {editIndex !== null ? 'actualizado' : 'añadido'} con éxito</Typography>
            <Button onClick={handleReset} sx={{ mt: 2 }} variant="contained" color="primary">Agregar Otro Precio</Button>
          </Box>
        ) : (
          <Box sx={{ width: '100%', maxWidth: '600px' }}>
            {renderStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                color="inherit"
                onClick={handleBack}
                disabled={activeStep === 0}
                variant="outlined"
              >
                Atrás
              </Button>
              <Box>
              {(editIndex !== null || activeStep > 0) && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancel}
                    sx={{ mr: 2 }}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={activeStep === steps.length - 1 ? handleAddOrUpdatePricing : handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={{ width: '100%', mt: 3 }}>
        <Container maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Filtrar por nombre</Typography>
            <TextField
              label="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1rem',
                },
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                },
              }}
            />
          </Box>
        </Container>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {`Mostrando ${filteredPrices.length} resultados`}
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: { xs: '20%', sm: '15%' }, fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'title'}
                    direction={orderBy === 'title' ? order : 'asc'}
                    onClick={() => handleRequestSort('title')}
                  >
                    Título
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: { xs: '20%', sm: '10%' }, fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'price'}
                    direction={orderBy === 'price' ? order : 'asc'}
                    onClick={() => handleRequestSort('price')}
                  >
                    Precio
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: { xs: '10%', sm: '10%' }, fontWeight: 'bold' }}>Orden</TableCell>
                <TableCell sx={{ width: { xs: '30%', sm: '15%' }, fontWeight: 'bold' }}>Descripción</TableCell>
                <TableCell sx={{ width: { xs: '10%', sm: '10%' }, fontWeight: 'bold' }}>Mes</TableCell>
                <TableCell sx={{ width: { xs: '20%', sm: '10%' }, fontWeight: 'bold' }}>Subtitulo</TableCell>
                <TableCell sx={{ width: { xs: '10%', sm: '10%' }, fontWeight: 'bold' }}>Estado</TableCell>
                <TableCell sx={{ width: { xs: '10%', sm: '10%' }, fontWeight: 'bold' }}>Destacado</TableCell>
                <TableCell sx={{ width: { xs: '20%', sm: '10%' }, fontWeight: 'bold' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedPrices.map((pricing, index) => (
                <TableRow key={pricing._id}>
                  <TableCell>{pricing.title}</TableCell>
                  <TableCell>${pricing.price}</TableCell>
                  <TableCell>{pricing.orden}</TableCell>
                  <TableCell>
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '5px',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                      {(!pricing.description || pricing.description.length === 0) ? (
                        'No tiene'
                      ) : (
                        pricing.description.map((desc, i) => (
                          <Chip
                            key={i}
                            label={desc}
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          />
                        ))
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{pricing.month.join(', ')}</TableCell>
                  <TableCell>{pricing.subheader || 'No tiene'}</TableCell>
                  <TableCell>{pricing.active ? 'Activo' : 'Inactivo'}</TableCell>
                  <TableCell>{pricing.highlight ? 'Sí' : 'No'}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Tooltip title="Editar">
                        <IconButton onClick={() => handleEdit(index)} size="small">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Eliminar">
                        <IconButton onClick={() => handleDelete(index)} size="small">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={filteredPrices.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}
            labelRowsPerPage="Filas por página:"
          />
        </Box>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>
        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          sx={{ '& .MuiDialog-paper': { p: 3, width: '400px', borderRadius: 2 } }}
        >
          <DialogTitle>
            <Typography variant="h6" component="h2" gutterBottom>
              Confirmación
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant="body1" paragraph>
              ¿Estás seguro de que deseas eliminar este precio?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenConfirmDialog(false)}
              color="inherit"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              onClick={confirmDelete}
              color="error"
              variant="contained"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AdminPricing;
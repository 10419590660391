import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import PoolIcon from '@mui/icons-material/Pool';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TvIcon from '@mui/icons-material/Tv';
import AttractionsIcon from '@mui/icons-material/Attractions';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import { useTheme } from '@mui/material/styles';

const items = [
  {
    icon: <NetworkWifiIcon />,
    title: 'Wi-Fi',
    description: 'Conéctate y disfruta de una conexión rápida y estable.',
  },
  {
    icon: <WhatshotIcon />,
    title: 'Quincho (Con parrilla)',
    description: 'Disfruta de una experiencia inigualable con nuestro quincho equipado con parrilla, ideal para reuniones y asados.',
  },
  {
    icon: <AudiotrackIcon />,
    title: 'Equipo de Musica',
    description: 'Disfruta de una calidad de sonido superior con nuestro equipo de música.',
  },
  {
    icon: <AttractionsIcon />,
    title: 'Juegos',
    description: 'Ofrecemos una variedad de juegos para que tanto niños como adultos disfruten, garantizando diversión para toda la familia.',
  },
  {
    icon: <PoolIcon />,
    title: 'Pileta',
    description: 'Disfruta de nuestra pileta de 11 x 5 metros, con profundidades que varían entre 1 y 2.5 metros para mayor comodidad. Incluye una valla de seguridad para proteger a los niños.',
  },
  {
    icon: <TvIcon />,
    title: 'Televisor',
    description: 'Disfruta de tu estadía con el televisor LCD de 40", que incluye acceso a Netflix, YouTube, Disney+ y Movistar TV.',
  }
];

export default function Highlights() {
  const theme = useTheme(); // Accede al tema actual
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000); // Simula tiempo de carga
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        bgcolor: theme.palette.mode === 'dark' ? 'hsl(220, 30%, 2%)' : 'hsl(220, 30%, 98%)',
        backgroundImage: theme.palette.mode === 'dark' 
          ? 'linear-gradient(to bottom, hsl(220, 30%, 5%), hsl(220, 30%, 2%))'
          : 'linear-gradient(to bottom, hsl(220, 30%, 100%), hsl(220, 30%, 98%))',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          textAlign: 'center',
        }}
      >
        <Box sx={{ px: { xs: 2, sm: 4 }, py: 4,  }}> {/* Padding horizontal y vertical para mejor espaciado */}
          <Typography
            component="h2"
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: { xs: 2, sm: 3, md: 4 }, // Incremento del margen inferior para mejor separación
              textAlign: 'center',
              fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.25rem' }, // Ajuste de tamaño de fuente
              lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 }, // Ajuste de interlineado para mejor legibilidad
              wordBreak: 'break-word', // Permite el ajuste de palabras largas
            }}
          >
            Comodidades
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              mb: { xs: 2, sm: 4 }, // Margen inferior para separar del contenido superior
              textAlign: 'center',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Ajuste del tamaño de fuente
              lineHeight: { xs: 1.5, sm: 1.6, md: 1.7 }, // Ajuste de interlineado para mejorar la lectura
              wordBreak: 'break-word', // Ajuste de palabras largas
            }}
          >
            Descubre las comodidades y servicios que ofrecemos para tu estancia en nuestro espacio. Desde un amplio quincho con parrilla hasta juegos para niños y adultos, cada detalle ha sido cuidadosamente diseñado para brindarte una experiencia memorable.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {loading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Stack
                  direction="column"
                  component={Card}
                  spacing={2}
                  useFlexGap
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'inherit' : 'text.primary',
                    p: 3,
                    height: '100%',
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? 'hsla(220, 25%, 25%, .3)' : 'hsla(220, 25%, 75%, .3)',
                    backgroundColor: theme.palette.mode === 'dark' ? 'grey.900' : 'white',
                    boxShadow: theme.shadows[3],
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: theme.shadows[5],
                    },
                  }}
                >
                  <Skeleton variant="circular" width={40} height={40} animation="wave" />
                  <Box>
                    <Skeleton variant="text" width="60%" animation="wave" />
                    <Skeleton variant="text" width="80%" animation="wave" />
                  </Box>
                </Stack>
              </Grid>
            ))
          ) : (
            items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Stack
                  direction="column"
                  component={Card}
                  spacing={2}
                  useFlexGap
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'inherit' : 'text.primary',
                    p: 3,
                    height: '100%',
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? 'hsla(220, 25%, 25%, .3)' : 'hsla(220, 25%, 75%, .3)',
                    backgroundColor: theme.palette.mode === 'dark' ? 'grey.900' : 'white',
                    boxShadow: theme.shadows[3],
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: theme.shadows[5],
                    },
                  }}
                >
                  <Box sx={{ opacity: '70%' }}>{item.icon}</Box>
                  <div>
                    <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700' }}>
                      {item.description}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </Box>
  );
}

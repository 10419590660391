// src/App.js
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/Interface/AppAppBar';
import Hero from './components/Home/Hero';
import Features from './components/Home/Features';
import Highlights from './components/Home/Highlights';
import FAQ from './components/Home/FAQ';
import Footer from './components/Interface/Footer';
import Pricing from './components/Home/Pricing';
import GoogleMaps from './components/Home/GoogleMaps';
import SignIn from './components/Login';
import ChangePassword from './components/ChangePassword';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoutes from './Admin/adminRoutes';
import ScrollToTop from './components/ScrollToTop';

function Home() {
  return (
    <Box sx={{ bgcolor: 'background.default', flex: 1 }}>
      <Hero />
      <Divider />
      <Features />
      <Divider />
      <Highlights />
      <Divider />
      <GoogleMaps />
      <Divider />
      <Pricing />
      <Divider />
      <FAQ />
      <Divider />
    </Box>
  );
}

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
          <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
          <ScrollToTop> {/* Envolvemos las rutas con ScrollToTop */}
            <Box
              component="main"
              sx={{
                flex: 1,
              }}
              >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<SignIn theme={defaultTheme} />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/admin/*" element={<AdminRoutes />} />
                  <Route path="/change-password" element={<ChangePassword />} />
                </Route>
              </Routes>
            </Box>
          </ScrollToTop>
          <Divider />
          <Footer />
      </ThemeProvider>
    </Router>
  );
}
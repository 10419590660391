// src/admin/adminRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import AdminSettings from './AdminSettings';
import AdminPricing from './AdminPricing';
import AdminFAQ from './AdminFAQ';
import AdminFeatures from './AdminFeatures';
import AdminPromotion from './AdminPromotion';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminDashboard />} />
      <Route path="setting" element={<AdminSettings />} />
      <Route path="pricing" element={<AdminPricing />} />
      <Route path="faq" element={<AdminFAQ />} />
      <Route path="feature" element={<AdminFeatures />} />
      <Route path="promotion" element={<AdminPromotion />} />
    </Routes>
  );
};

export default AdminRoutes;

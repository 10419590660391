import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, useTheme, Dialog, DialogTitle, DialogContent, IconButton, Typography, Button, Slide, Stack } from '@mui/material';
import { styled, alpha } from '@mui/system';
import PricingHeader from './PricingHeader';
import PricingTabs from './PricingTabs';
import TierCard from './TierCard';
import WeekendTierCard from './WeekendTierCard';
import PromotionCard from './PromotionCard';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LaLomitaIcon from '../../Interface/LaLomitaIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function Pricing({ tiers, promotions, loading, errorPricing }) {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [settings, setSettings] = useState({});
    
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch('https://api.espaciolalomita.com/api/settings');
        const data = await response.json();
        if (data.length > 0) {
          setSettings(data[0]);
        }
      } catch (err) {
        console.error('Error fetching Settings:', err);
      }
    };

    fetchSettings();
  }, []);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleContactButtonClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const sortedTiers = tiers
  .filter(tier => tier.active)
  .sort((a, b) => a.orden - b.orden); // Ordena de menor a mayor

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container justifyContent="center">
        <PricingHeader showSkeleton={loading} />
      </Grid>
      <Box sx={{ mb: 4 }} />
      {errorPricing ? (
        <Container
        id="pricing"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        >
            <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
            <ErrorOutlineIcon color="error" fontSize="large" />
            <Typography variant="h5" color="error">
              Ha ocurrido un error
            </Typography>
          </Stack>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
            {errorPricing}
          </Typography>
        </Container>
      ) : (
        <>
          <PricingTabs tabValue={tabValue} handleTabChange={handleTabChange} theme={theme}/>
          <Grid container spacing={2} sx={{ mt: 4, justifyContent: 'center' }}>
            {tabValue === 0 &&
              sortedTiers.map((tier) => (
                <Grid item xs={12} sm={6} md={4} key={tier.title}>
                  {tier.highlight ? (
                    <WeekendTierCard
                      tier={tier}
                      handleContactButtonClick={handleContactButtonClick}
                    />
                  ) : (
                    <TierCard
                      tier={tier}
                      handleContactButtonClick={handleContactButtonClick}
                    />
                  )}
                </Grid>
              ))}
            {tabValue === 1 &&
              promotions.map((promotion) =>
                promotion.subcategories.map((subcat, index) => (
                  <Grid item xs={12} sm={6} md={4} key={`${promotion.title}-${index}`}>
                    <PromotionCard
                      promotion={promotion}
                      subcat={subcat}
                      handleContactButtonClick={handleContactButtonClick}
                    />
                  </Grid>
                ))
              )}
          </Grid>
          <CustomDialog
            open={openDialog}
            onClose={handleCloseDialog}
            TransitionComponent={TransitionDialog}
          >
            <DialogTitle sx={{ textAlign: 'center', paddingBottom: 0 }}>
              <LaLomitaIcon sx={{ width: '120px', height: '120px' }} />
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                }}
              >
                {/* Contact Information */}
                <GradientBox
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Contacto
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PhoneIcon sx={{ color: 'inherit' }} />
                    <Typography variant="body1">{settings.telefono}</Typography>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Redes Sociales
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      href={settings.instagram}
                      size="small"
                      sx={{ borderRadius: '30%', minWidth: '36px', height: '36px' }}
                    >
                      <InstagramIcon />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      href={settings.facebook}
                      size="small"
                      sx={{ borderRadius: '30%', minWidth: '36px', height: '36px' }}
                    >
                      <FacebookIcon />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      href={`https://api.whatsapp.com/send?phone=541135416036&text=${encodeURIComponent(
                        settings.whatsapp
                      )}`}
                      size="small"
                      sx={{ borderRadius: '30%', minWidth: '36px', height: '36px' }}
                    >
                      <WhatsAppIcon />
                    </Button>
                  </Box>
                </GradientBox>
              </Box>
            </DialogContent>
          </CustomDialog>
        </>
      )}
    </Box>
  );
}

// Transition Component for the Dialog
const TransitionDialog = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GradientBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[5],
  color: 'white',
  transition: 'background 0.3s',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2), // Reduce el padding en pantallas pequeñas
  },
}));

// Custom Styled Dialog Component
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '20vw',
    borderRadius: theme.shape.borderRadius * 1.5,
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    [theme.breakpoints.down('md')]: {
      maxWidth: '95vw',
      padding: theme.spacing(2),
    },
  },
}));
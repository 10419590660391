import React from 'react';
import { Container, Typography, Grid, Paper, Avatar, Button, Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from 'react-router-dom';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LockIcon from '@mui/icons-material/Lock';

const AdminDashboard = () => {
  return (
    <Box
    sx={{
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        pt: 8,
    }}
    >
      <Container
        sx={(theme) => ({
          pt: `${theme.spacing(16)}`, // Ajusta el padding superior según la altura de la AppBar
          pb: { xs: theme.spacing(4), sm: theme.spacing(8) },
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: theme.spacing(2), sm: theme.spacing(4) },
          borderRadius: 2,
        })}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: 'text.primary',
            width: { xs: '100%', md: '60%' },
            textAlign: 'center',
            mb: { xs: 3, sm: 5 },
          }}
        >
          Panel de Administración
        </Typography>
        <Grid container spacing={{ xs: 3, md: 4 }} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{
                padding: { xs: 2, md: 3 },
                textAlign: 'center',
                position: 'relative',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
                <SettingsIcon />
              </Avatar>
              <Typography variant="h6" gutterBottom>
                Configuración
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Ajusta las configuraciones del sistema y personaliza las preferencias.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/admin/setting"
                sx={{ mt: 2 }}
              >
                Ir a Configuración
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{
                padding: { xs: 2, md: 3 },
                textAlign: 'center',
                position: 'relative',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
                <PriceCheckIcon />
              </Avatar>
              <Typography variant="h6" gutterBottom>
                Precios
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Gestiona y actualiza los precios de los servicios ofrecidos.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/admin/pricing"
                sx={{ mt: 2 }}
              >
                Ir a Precios
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{
                padding: { xs: 2, md: 3 },
                textAlign: 'center',
                position: 'relative',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
                <HelpOutlineIcon />
              </Avatar>
              <Typography variant="h6" gutterBottom>
                Preguntas Frecuentes
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Revisa y actualiza las preguntas frecuentes para los usuarios.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/admin/faq"
                sx={{ mt: 2 }}
              >
                Ir a FAQs
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{
                padding: { xs: 2, md: 3 },
                textAlign: 'center',
                position: 'relative',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
                <LockIcon />
              </Avatar>
              <Typography variant="h6" gutterBottom>
                Cambiar Contraseña
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Actualiza tu contraseña para mejorar la seguridad de tu cuenta.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/change-password" // Cambiar enlace a la página de cambio de contraseña
                sx={{ mt: 2 }}
              >
                Ir a Cambiar Contraseña
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AdminDashboard;
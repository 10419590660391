import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import Lightbox from 'react-18-image-lightbox';
import { useTheme } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LaLomitaFullLogo from '../Interface/LaLomitaFullLogo';
import Skeleton from '@mui/material/Skeleton';
import 'react-18-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LetrasLogo from '../Interface/LetrasLogo';

const images = [
  '/images/9.webp',
  '/images/IMG_2172.webp',
  '/images/IMG_2188.webp',
  '/images/5.webp',
  '/images/6.webp',
  '/images/7.webp',
  '/images/8.webp',
];

export default function Hero() {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [photoIndex, setPhotoIndex] = React.useState(-1);
  const [loading, setLoading] = React.useState(true);
  const [showSkeleton, setShowSkeleton] = React.useState(true);
  const sliderRef = React.useRef(null);
  const thumbnailSliderRef = React.useRef(null);
  const theme = useTheme();

  React.useEffect(() => {
    const imageLoader = () => {
      let loaded = 0;
      const total = images.length;

      images.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loaded += 1;
          if (loaded === total) {
            setLoading(false);
            setShowSkeleton(false);
          }
        };
      });
    };

    const timer = setTimeout(() => {
      imageLoader();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleOpenLightbox = (index) => {
    setPhotoIndex(index);
    document.getElementById('navbar').style.display = 'none';
  };

  const handleCloseLightbox = () => {
    setPhotoIndex(-1);
    document.getElementById('navbar').style.display = 'block';
  };

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
    sliderRef.current.slickGoTo(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Habilitar reproducción automática
    autoplaySpeed: 5000, // Velocidad de reproducción automática en milisegundos (3 segundos)
    afterChange: (index) => setCurrentSlide(index),
    arrows: true,
    prevArrow: (
      <NavigateBeforeIcon
        sx={{
          color: theme.palette.mode === 'dark' ? 'primary.light' : 'primary.main',
          fontSize: 40,
          zIndex: 2,
          position: 'absolute',
          top: '50%',
          left: 10,
          transform: 'translateY(-50%)',
          backgroundColor: `${theme.palette.background.paper} !important`,
          borderRadius: '50%',
          padding: 1,
          boxShadow: `0 2px 8px rgba(0, 0, 0, 0.4) !important`,
          opacity: '1 !important',
          '&:hover': {
            color: theme.palette.mode === 'dark' ? 'primary.light' : 'primary.main',
            backgroundColor: `${theme.palette.background.paper} !important`,
            boxShadow: `0 2px 8px rgba(0, 0, 0, 0.4) !important`,
          },
        }}
      />
    ),
    nextArrow: (
      <NavigateNextIcon
        sx={{
          color: theme.palette.mode === 'dark' ? 'primary.light' : 'primary.main',
          fontSize: 40,
          zIndex: 2,
          position: 'absolute',
          top: '50%',
          right: 10,
          transform: 'translateY(-50%)',
          backgroundColor: `${theme.palette.background.paper} !important`,
          borderRadius: '50%',
          padding: 1,
          boxShadow: `0 2px 8px rgba(0, 0, 0, 0.4) !important`,
          opacity: '1 !important',
          '&:hover': {
            color: theme.palette.mode === 'dark' ? 'primary.light' : 'primary.main',
            backgroundColor: `${theme.palette.background.paper} !important`,
            boxShadow: `0 2px 8px rgba(0, 0, 0, 0.4) !important`,
          },
        }}
      />
    ),
  };  

  const thumbnailSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    autoplay: true, // Reproducción automática
    autoplaySpeed: 5000, // Velocidad del auto play
    pauseOnHover: true, // Pausa al pasar el ratón por encima
    cssEase: 'ease-in-out', // Transición suave
  };

  return (
    <Box
    sx={{
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        pt: 8,
    }}
    >
      <Container
        id="home"
        sx={(theme) => ({
          pt: `${theme.spacing(16)}`, // Ajusta el padding superior según la altura de la AppBar
          pb: { xs: theme.spacing(4), sm: theme.spacing(8) },
          position: 'relative',
          minHeight: { lg: '100vh' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: theme.spacing(2), sm: theme.spacing(4) },
          borderRadius: 2,
        })}
      >
        <Box
          sx={{ 
            px: { xs: 2, sm: 4 }, 
            py: 4, 
            display: 'flex', // Para usar flexbox
            flexDirection: 'column', // Alinear los elementos en columna
            alignItems: 'center', // Alinear horizontalmente en el centro
            justifyContent: 'center', // Alinear verticalmente en el centro
            width: '100%', 
            mx: 'auto' // Centrar horizontalmente
          }}
          spacing={4}
        >
          <LetrasLogo
            sx={{
              width: { xs: '250px', sm: '280px', md: '310px', lg: '330px' },
              height: '100px',
            }}
          />
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              mb: { xs: 2, sm: 4 }, // Margen inferior para separar del contenido superior
              textAlign: 'center',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Ajuste del tamaño de fuente
              lineHeight: { xs: 1.5, sm: 1.6, md: 1.7 }, // Ajuste de interlineado para mejorar la lectura
              wordBreak: 'break-word', // Ajuste de palabras largas
            }}
          >
            Si buscas un lugar ideal para disfrutar con tus seres queridos, este espacio ofrece el ambiente perfecto para relajarte y divertirte.
          </Typography>
        </Box>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: { xs: 300, md: 600 },
              display: showSkeleton ? 'block' : 'none',
              backgroundColor: theme.palette.background.default,
              zIndex: 1,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ borderRadius: 2 }}
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: { xs: 300, md: 600 },
              visibility: showSkeleton ? 'hidden' : 'visible',
              overflow: 'hidden',
              borderRadius: 2,
              transition: 'opacity 0.3s ease-in-out',
              zIndex: 1,
            }}
          >
            <Slider {...settings} ref={sliderRef}>
              {images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 2,
                    height: { xs: 300, md: 600 },
                    boxShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`,
                    '& img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      cursor: 'default',
                    }
                  }}
                >
                  <img
                    src={image}
                    alt={`Imagen ${index + 1}`}
                    style={{ pointerEvents: 'none' }}
                    onClick={() => handleOpenLightbox(index)}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>

        <Box sx={{ width: '100%', mt: 2, position: 'relative' }}>
          {/* Skeleton de carga */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: { xs: 40, md: 80 },
              display: showSkeleton ? 'block' : 'none',
              backgroundColor: theme.palette.background.default,
              zIndex: 2,  // Ajuste del zIndex para el Skeleton
              transition: 'opacity 0.3s ease-in-out',
              opacity: showSkeleton ? 1 : 0,  // Transición de opacidad suave
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ borderRadius: 2 }}
            />
          </Box>
          {/* Slider de miniaturas */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: { xs: 40, md: 80 },
              visibility: showSkeleton ? 'hidden' : 'visible',
              overflow: 'hidden',
              borderRadius: 2,
              zIndex: 1,
              opacity: showSkeleton ? 0 : 1,  // Ajuste de la opacidad para una transición suave
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <Slider {...thumbnailSettings} ref={thumbnailSliderRef}>
              {images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 2,
                    width: { xs: 80, sm: 100 },
                    height: { xs: 40, md: 80 },
                    boxShadow: currentSlide === index
                      ? `0 4px 10px rgba(0, 0, 0, 0.4)`
                      : `0 2px 5px rgba(0, 0, 0, 0.3)`,
                    cursor: 'pointer',
                    border: currentSlide === index
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                    marginRight: 1, // Espacio entre imágenes
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      opacity: currentSlide === index ? 1 : 0.9,
                    },
                    '& img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      filter: currentSlide === index ? 'none' : 'grayscale(80%)',
                      transition: 'filter 0.3s ease-in-out, transform 0.3s ease-in-out',
                    },
                  }}
                  onClick={() => handleThumbnailClick(index)}
                  aria-label={`Thumbnail ${index + 1}`}
                >
                  <img src={image} alt={`Thumbnail ${index + 1}`} />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>

      {photoIndex >= 0 && (
        <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={handleCloseLightbox}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % images.length)
        }
        />
      )}
      </Container>
    </Box>
  );
}
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AdminFAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '', severity: '' });
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    fetch('https://api.espaciolalomita.com/api/faqs')
      .then(response => response.json())
      .then(data => {
        setFaqs(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching FAQs:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const handleAddOrUpdateFAQ = () => {
    if (!question.trim() || !answer.trim()) {
      setNotification({ open: true, message: 'Por favor, complete todos los campos.', severity: 'warning' });
      return;
    }

    setLoading(true);
    const url = editIndex !== null ? `https://api.espaciolalomita.com/api/faqs/${faqs[editIndex]._id}` : 'https://api.espaciolalomita.com/api/faqs';
    const method = editIndex !== null ? 'PUT' : 'POST';

    fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question, answer }),
    })
      .then(response => response.json())
      .then(data => {
        if (editIndex !== null) {
          setFaqs(faqs.map((faq, index) => (index === editIndex ? data : faq)));
          setEditIndex(null);
          setNotification({ open: true, message: 'Pregunta actualizada con éxito.', severity: 'success' });
        } else {
          setFaqs([...faqs, data]);
          setNotification({ open: true, message: 'Pregunta añadida con éxito.', severity: 'success' });
        }
        setQuestion('');
        setAnswer('');
        setLoading(false);
      })
      .catch(error => {
        console.error('Error saving FAQ:', error);
        setNotification({ open: true, message: 'Error al guardar la pregunta.', severity: 'error' });
        setLoading(false);
      });
  };

  const handleEdit = (index) => {
    setQuestion(faqs[index].question);
    setAnswer(faqs[index].answer);
    setEditIndex(index);
  };

  const handleDelete = () => {
    setLoading(true);
    const faqId = faqs[deleteIndex]._id;

    fetch(`https://api.espaciolalomita.com/api/faqs/${faqId}`, {
      method: 'DELETE',
    })
      .then(() => {
        setFaqs(faqs.filter((_, i) => i !== deleteIndex));
        setNotification({ open: true, message: 'Pregunta eliminada con éxito.', severity: 'success' });
        setDeleteDialogOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error deleting FAQ:', error);
        setNotification({ open: true, message: 'Error al eliminar la pregunta.', severity: 'error' });
        setLoading(false);
      });
  };

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteIndex(null);
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: '', severity: '' });
  };

  useEffect(() => {
    if (error) {
      setNotification({ open: true, message: `Error al cargar la configuración: ${error}`, severity: 'error' });
    }
  }, [error]);

  return (
    <Box
    sx={{
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        pt: 8,
    }}
    >
      <Container
        sx={(theme) => ({
          pt: `${theme.spacing(16)}`, // Ajusta el padding superior según la altura de la AppBar
          pb: { xs: theme.spacing(4), sm: theme.spacing(8) },
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: theme.spacing(2), sm: theme.spacing(4) },
          borderRadius: 2,
        })}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: 'text.primary',
            width: { sm: '100%', md: '60%' },
            textAlign: 'center',
          }}
        >
          Gestión de Preguntas Frecuentes
        </Typography>
        {/* <Box component="form" sx={{ mt: 2, width: '100%', maxWidth: '600px' }}> */}
        <Box
          component="form"
          sx={{
            mt: 2,
            width: '100%',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
        <TextField
          label="Pregunta"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          fullWidth
          margin="normal"
          required
          placeholder="Escribe la pregunta aquí..."
          variant="outlined"
          sx={{
            borderRadius: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: 1,
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'text.secondary',
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: 'primary.main',
            },
          }}
        />
        <TextField
          label="Respuesta"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          fullWidth
          margin="normal"
          required
          multiline
          rows={4}
          placeholder="Escribe la respuesta aquí..."
          variant="outlined"
          sx={{
            borderRadius: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: 1,
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'text.secondary',
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: 'primary.main',
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddOrUpdateFAQ}
          sx={{ mt: 2, borderRadius: 1, textTransform: 'none' }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : (editIndex !== null ? 'Actualizar Pregunta' : 'Añadir Pregunta')}
        </Button>
        </Box>
        <Box sx={{ width: '100%', mt: 3 }}>
          {faqs.map((faq, index) => (
            <Accordion
              sx={{
                mb: 2,
                borderRadius: 1,
                boxShadow: 1,
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  boxShadow: 3,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
                sx={{
                  bgcolor: 'background.default',
                  borderRadius: 1,
                }}
              >
                <Typography component="h3" variant="subtitle2" noWrap>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ maxWidth: '100%' }}>
                  {faq.answer}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <IconButton onClick={() => handleEdit(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDeleteDialog(index)} sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>
        <Dialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
        >
          <DialogTitle>Confirmar Eliminación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas eliminar esta pregunta? Esta acción no se puede deshacer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
            <Button onClick={handleDelete} color="error">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AdminFAQ;
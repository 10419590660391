import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Container,
  Box,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded';

const iconOptions = [
  { label: 'Quincho', value: <ViewQuiltRoundedIcon /> },
  { label: 'Baño', value: <ShowerRoundedIcon /> },
];

const AdminFeatures = () => {
  const [features, setFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState({
    icon: '',
    title: '',
    description: '',
    image: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/features');
        if (!response.ok) {
          throw new Error('Error al cargar las características');
        }
        const data = await response.json();
        setFeatures(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchFeatures();
  }, []);

  const handleChange = (e) => {
    setSelectedFeature({
      ...selectedFeature,
      [e.target.name]: e.target.value,
    });
  };

  const handleIconChange = (e) => {
    const selectedIcon = iconOptions.find(icon => icon.label === e.target.value);
    setSelectedFeature({
      ...selectedFeature,
      icon: selectedIcon ? selectedIcon.value : '',
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedFeature({
        ...selectedFeature,
        image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `http://localhost:5000/api/features${isEditing ? `/${selectedFeature._id}` : ''}`;
      const method = isEditing ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedFeature),
      });

      if (!response.ok) {
        throw new Error(`Error al ${isEditing ? 'actualizar' : 'crear'} la característica`);
      }

      const updatedFeature = await response.json();

      if (isEditing) {
        setFeatures(features.map(f => f._id === updatedFeature._id ? updatedFeature : f));
      } else {
        setFeatures([...features, updatedFeature]);
      }

      setSnackbarMessage(`Característica ${isEditing ? 'actualizada' : 'creada'} con éxito`);
      setSnackbarSeverity('success');
      setSelectedFeature({ icon: '', title: '', description: '', image: '' });
      setIsEditing(false);
    } catch (err) {
      setSnackbarMessage(`Error al ${isEditing ? 'actualizar' : 'crear'} la característica: ${err.message}`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (feature) => {
    setSelectedFeature(feature);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/api/features/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error al eliminar la característica');
      }

      setFeatures(features.filter(f => f._id !== id));
      setSnackbarMessage('Característica eliminada con éxito');
      setSnackbarSeverity('success');
    } catch (err) {
      setSnackbarMessage(`Error al eliminar la característica: ${err.message}`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <Typography>Cargando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        {isEditing ? 'Editar Característica' : 'Crear Característica'}
      </Typography>
      <Box elevation={3} sx={{ padding: 4, maxWidth: 800 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Ícono</InputLabel>
                <Select
                  value={selectedFeature.icon}
                  onChange={handleIconChange}
                  label="Ícono"
                  required
                >
                  {iconOptions.map((icon) => (
                    <MenuItem key={icon.label} value={icon.label}>
                      {icon.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                value={selectedFeature.title}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="description"
                value={selectedFeature.description}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ marginTop: 2 }}
              >
                Subir Imagen
                <input
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </Button>
              {selectedFeature.image && (
                <img
                  src={selectedFeature.image}
                  alt="Previsualización"
                  style={{ marginTop: 10, maxWidth: '100%', height: 'auto' }}
                />
              )}
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {isEditing ? 'Guardar Cambios' : 'Crear Característica'}
            </Button>
          </Box>
        </form>
      </Box>
      <Box mt={4} sx={{ width: '100%', maxWidth: 800 }}>
        <Typography component="h3" variant="h5" sx={{ mb: 2 }}>
          Lista de Características
        </Typography>
        <Paper elevation={3}>
          <List>
            {features.map((feature) => (
              <div key={feature._id}>
                <ListItem>
                  <ListItemText
                    primary={feature.title}
                    secondary={feature.description}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(feature)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(feature._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Paper>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminFeatures;
// src/components/ScrollToTop.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Verifica si la ruta actual no es la página de inicio ("/")
    if (location.pathname !== '/') {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
};

export default ScrollToTop;

import React from 'react';
import { Card, CardContent, Box, Typography, Divider, Chip, Button } from '@mui/material';
import GradeIcon from '@mui/icons-material/Grade';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; // Un ícono opcional para representar los meses

export default function PromotionCard({ promotion, subcat, handleContactButtonClick }) {
  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        border: 'none',
        backgroundColor: 'background.paper',
        boxShadow: 3,
        ...(promotion.title === 'Horario nocturno' && {
          backgroundColor: 'background.default',
          color: 'primary.contrastText',
          boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.4)`,
        }),
      }}
    >
      <CardContent>
        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <Typography component="h3" variant="h6" sx={{ color: 'text.primary' }}>
            {promotion.title}
          </Typography>
          <Chip
            icon={<GradeIcon />}
            label="Especial"
            size="small"
            sx={{
              borderColor: 'hsla(220, 60%, 10%, 0.3)',
              backgroundColor: 'hsla(220, 60%, 10%, 0.1)',
              '& .MuiChip-label': {
                color: 'hsl(220, 90%, 30%)',
              },
              '& .MuiChip-icon': {
                color: 'primary.light',
              },
            }}
          />
        </Box>
        <Typography component="h4" variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: 'text.primary' }}>
          {subcat.days}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2, color: 'text.primary' }}>
          <Typography component="h3" variant="h4">
            ${subcat.price}
          </Typography>
          <Typography component="h3" variant="h6" sx={{ ml: 1 }}>
            {promotion.tiempo}
          </Typography>
        </Box>
        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
        {promotion.description.map((line) => (
          <Box key={line} sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
            <CheckCircleRoundedIcon
              sx={{
                width: 20,
                color: 'primary.main',
              }}
            />
            <Typography variant="subtitle2" component="span" sx={{ color: 'black' }}>
              {line}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
        {promotion.month && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mb: 6,
              p: 2, // Padding for spacing inside the container
              borderRadius: 2, // Rounded corners for a softer look
              backgroundColor: 'background.paper', // White background to match the Card
              border: '1px solid',
              borderColor: 'divider', // Light gray border for separation
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarMonthIcon sx={{ color: 'primary.main', fontSize: 24 }} /> {/* Icon color in gray */}
              <Typography variant="subtitle2" component="span" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                Precio aplicable en:
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
              {promotion.month.map((month, index) => (
                <Chip
                  key={index}
                  label={month}
                  size="small"
                  sx={{
                    borderRadius: 16, // Rounded corners for chips
                    color: 'text.primary', // Text color for chips
                    border: '1px solid',
                    borderColor: 'divider', // Light gray border for chips
                    backgroundColor: 'background.default', // Slightly off-white background for chips
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
        <Button fullWidth variant="outlined" onClick={handleContactButtonClick}>
          Contactanos
        </Button>
      </CardContent>
    </Card>
  );
}

import React from 'react';
import { Card, CardContent, CardActions, Box, Typography, Divider, Button, Chip } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function TierCard({ tier, handleContactButtonClick }) {
  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        border: 'none',
        backgroundColor: 'background.paper',
        boxShadow: 3,
        borderRadius: 2, // Rounded corners for a modern look
        maxWidth: 360,
        margin: 'auto',
        '@media (max-width:600px)': {
          maxWidth: '100%',
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Typography component="h3" variant="h5" sx={{ fontWeight: 'bold' }}>
            {tier.title}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
            <Typography component="h3" variant="h4" sx={{ fontWeight: 'bold' }}>
              ${tier.price}
            </Typography>
            <Typography component="span" variant="body1">
              por día
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
        {tier.description.map((line, index) => (
          <Box key={index} sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
            <CheckCircleRoundedIcon sx={{ width: 20, color: 'primary.main' }} />
            <Typography variant="subtitle2" component="span" sx={{ color: 'text.primary' }}>
              {line}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
        {tier.month && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '150px',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarMonthIcon sx={{ color: 'primary.main', fontSize: 24 }} />
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                Precio aplicable en:
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
              {tier.month.map((month, index) => (
                <Chip
                  key={index}
                  label={month}
                  size="small"
                  sx={{
                    borderRadius: 16,
                    color: 'text.primary',
                    border: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: 'background.default',
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
      </CardContent>
      <CardActions>
        <Button fullWidth variant="contained" onClick={handleContactButtonClick}>
          Contáctanos
        </Button>
      </CardActions>
    </Card>
  );
}

// src/Admin/AdminSettings.js
import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
  Grid,
  Container,
  Box,
  Snackbar,
  Alert
} from '@mui/material';

const AdminSettings = () => {
  const [settings, setSettings] = useState({
    direccion: '',
    telefono: '',
    email: '',
    instagram: '',
    facebook: '',
    whatsapp: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isExisting, setIsExisting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch('https://api.espaciolalomita.com/api/settings');
        if (!response.ok) {
          throw new Error('Error al cargar la configuración');
        }
        const data = await response.json();
        if (data.length > 0) {
          setSettings(data[0]); // Suponiendo que siempre habrá un único documento
          setIsExisting(true);
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    setSettings({
      ...settings,
      [e.target.name]: e.target.value,
    });
  };

  // Función para formatear el texto para incluirlo en una URL
  const formatTextForWhatsApp = (text) => {
    return encodeURIComponent(text);
  };

  // Función para generar la URL de WhatsApp con el mensaje formateado
  const generateWhatsAppUrl = (phoneNumber, message) => {
    const baseUrl = "https://api.whatsapp.com/send";
    const formattedText = formatTextForWhatsApp(message);
    return `${baseUrl}?phone=${phoneNumber}&text=${formattedText}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `https://api.espaciolalomita.com/api/settings${isExisting ? `/${settings._id}` : ''}`;
      const method = isExisting ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings),
      });

      if (!response.ok) {
        throw new Error(`Error al ${isExisting ? 'actualizar' : 'crear'} la configuración`);
      }

      if (!isExisting) {
        setIsExisting(true);
      }
      setSnackbarMessage(`Configuración ${isExisting ? 'actualizada' : 'creada'} con éxito`);
      setSnackbarSeverity('success');
    } catch (err) {
      setSnackbarMessage(`Error al ${isExisting ? 'actualizar' : 'crear'} la configuración: ${err.message}`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleGenerateWhatsAppUrl = () => {
    const phoneNumber = '541135416036'; // Asegúrate de que este campo contenga el número de teléfono
    const message = settings.whatsapp;
    const url = generateWhatsAppUrl(phoneNumber, message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (error) {
      setSnackbarMessage(`Error al cargar la configuración: ${error}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [error]);

  return (
    <Box
    sx={{
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        pt: 8,
    }}
    >
      <Container
        sx={(theme) => ({
          pt: `${theme.spacing(16)}`, // Ajusta el padding superior según la altura de la AppBar
          pb: { xs: theme.spacing(4), sm: theme.spacing(8) },
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: theme.spacing(2), sm: theme.spacing(4) },
          borderRadius: 2,
        })}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: 'text.primary',
            width: { sm: '100%', md: '60%' },
            textAlign: 'center',
          }}
        >
          {isExisting ? 'Editar Configuración' : 'Crear Configuración'}
        </Typography>
        <Box elevation={3} sx={{ padding: 4, maxWidth: 800 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Dirección"
                  name="direccion"
                  value={settings.direccion}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Teléfono"
                  name="telefono"
                  value={settings.telefono}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={settings.email}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Instagram"
                  name="instagram"
                  value={settings.instagram}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Facebook"
                  name="facebook"
                  value={settings.facebook}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Grid>
              {/* 
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="WhatsApp"
                  name="whatsapp"
                  value={settings.whatsapp}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Grid>
              */}
            </Grid>
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                {isExisting ? 'Guardar Cambios' : 'Crear Configuración'}
              </Button>
            </Box>
            {/* <Box mt={2}>
              <Button 
                variant="outlined" 
                color="secondary" 
                onClick={handleGenerateWhatsAppUrl} 
                fullWidth
              >
                Generar URL de WhatsApp
              </Button>
            </Box>*/}
          </form>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AdminSettings;
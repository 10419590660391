// src/components/ChangePassword.js
import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider, signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Asegúrate de que esta ruta sea correcta
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Puede ser 'success' o 'error'
  const navigate = useNavigate();

  const validatePasswords = () => {
    return newPassword === confirmPassword && newPassword.length >= 6;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validatePasswords()) {
      setSnackbarMessage('Las contraseñas no coinciden o son demasiado cortas (mínimo 6 caracteres).');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const user = auth.currentUser;

    if (!user) {
      setSnackbarMessage('Usuario no autenticado');
      setSnackbarSeverity('error');
      setLoading(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      // Re-authenticate the user
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      
      // Update the password
      await updatePassword(user, newPassword);

      // Sign out the user
      await signOut(auth);

      setSnackbarMessage('Contraseña actualizada exitosamente. Por favor, inicie sesión nuevamente.');
      setSnackbarSeverity('success');
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (error) {
      setSnackbarMessage('Error al actualizar la contraseña. Verifica tu contraseña actual e inténtalo de nuevo.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
        <Box
        sx={{
            width: '100%',
            minHeight: '100vh',
            background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
            pt: 8,
        }}
        >
            <Container component="main" maxWidth="xs" sx={{ mb: 4, mt: 10 }}>
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 3,
                    p: 4,
                    maxWidth: 400,
                    mx: 'auto',
                    zIndex: 1,
                    position: 'relative',
                }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Cambiar Contraseña
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="current-password"
                    label="Contraseña Actual"
                    name="current-password"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="new-password"
                    label="Nueva Contraseña"
                    name="new-password"
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="confirm-password"
                    label="Confirmar Nueva Contraseña"
                    name="confirm-password"
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading || !validatePasswords()}
                    sx={{ mt: 2 }}
                    >
                    {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Cambiar Contraseña'}
                    </Button>
                </Box>
                </Box>
            </Container>
        </Box>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        >
        <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
        >
            {snackbarMessage}
        </Alert>
        </Snackbar>
    </div>
  );
}
import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

export default function PricingHeader({ showSkeleton }) {
  return (
    <Box sx={{ px: { xs: 2, sm: 4 }, py: 4,  }}>
      {showSkeleton ? (
        <Skeleton variant="text" width={200} height={40} />
      ) : (
      <Typography
        component="h2"
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: { xs: 2, sm: 3, md: 4 }, // Incremento del margen inferior para mejor separación
          textAlign: 'center',
          fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.25rem' }, // Ajuste de tamaño de fuente
          lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 }, // Ajuste de interlineado para mejor legibilidad
          wordBreak: 'break-word', // Permite el ajuste de palabras largas
        }}
      >
        Precios
      </Typography>
      )}
      {showSkeleton ? (
        <Skeleton variant="text" width={200} height={40} />
      ) : (
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          mb: { xs: 2, sm: 4 }, // Margen inferior para separar del contenido superior
          textAlign: 'center',
          fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Ajuste del tamaño de fuente
          lineHeight: { xs: 1.5, sm: 1.6, md: 1.7 }, // Ajuste de interlineado para mejorar la lectura
          wordBreak: 'break-word', // Ajuste de palabras largas
        }}
      >
        Descubre nuestras opciones y deja que te ayudemos a crear un día inolvidable con tu familia y amigos.
      </Typography>
      )}
    </Box>
  );
}
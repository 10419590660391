import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded';
import WeekendRoundedIcon from '@mui/icons-material/WeekendRounded';
import DeckRoundedIcon from '@mui/icons-material/DeckRounded';
import TableRestaurantRoundedIcon from '@mui/icons-material/TableRestaurantRounded';
import KitchenRoundedIcon from '@mui/icons-material/KitchenRounded';
import MicrowaveRoundedIcon from '@mui/icons-material/MicrowaveRounded';
import LocalPizzaRoundedIcon from '@mui/icons-material/LocalPizzaRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
import ChairRoundedIcon from '@mui/icons-material/ChairRounded';
import UmbrellaRoundedIcon from '@mui/icons-material/UmbrellaRounded';
import NaturePeopleRoundedIcon from '@mui/icons-material/NaturePeopleRounded';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import TvRoundedIcon from '@mui/icons-material/TvRounded';
import BedRoundedIcon from '@mui/icons-material/BedRounded';
import PowerRoundedIcon from '@mui/icons-material/PowerRounded';
import AirRoundedIcon from '@mui/icons-material/AirRounded';
import ChildCareRoundedIcon from '@mui/icons-material/ChildCareRounded';
import SportsSoccerRoundedIcon from '@mui/icons-material/SportsSoccerRounded';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Quincho (con parrilla)',
    description: 'Espacio amplio con parrilla para asados.',
    image: '/images/IMG_2174.webp',
  },
  {
    icon: <ShowerRoundedIcon />,
    title: '1 baño con ducha',
    description: 'Baño completo con ducha.',
    image: '/images/baño.webp',
  },
  {
    icon: <WeekendRoundedIcon />,
    title: '2 camastros con su mesita',
    description: 'Camastros cómodos con mesita.',
    image: '/images/IMG_2368.webp',
  },
  {
    icon: <DeckRoundedIcon />,
    title: '6 reposeras con 3 mesitas',
    description: 'Reposeras cómodas con mesitas.',
    image: '/images/IMG_2381.webp',
  },
  {
    icon: <TableRestaurantRoundedIcon />,
    title: '3 mesas de 2mts c/u y 30 sillas',
    description: 'Mesas amplias con sillas.',
    image: '/images/IMG_2371.webp',
  },
  {
    icon: <KitchenRoundedIcon />,
    title: 'Heladera con freezer',
    description: 'Heladera',
    image: '',
  },
  {
    icon: <MicrowaveRoundedIcon />,
    title: 'Cocina',
    description: 'Cocina eléctrica completa, vajilla y pava eléctrica.',
    image: '/images/IMG_2183.webp',
  },
  {
    icon: <LocalPizzaRoundedIcon />,
    title: 'Horno pizzero',
    description: 'Horno especial para pizzas.',
    image: '/images/IMG_2369.webp',
  },
  {
    icon: <ErrorOutlineIcon />,
    title: 'Pava eléctrica',
    description: 'Pava eléctrica para calentar agua.',
    image: '',
  },
  {
    icon: <RestaurantRoundedIcon />,
    title: 'Vajilla',
    description: 'Vajilla completa.',
    image: '',
  },
  {
    icon: <MusicNoteRoundedIcon />,
    title: 'Equipo de música',
    description: 'Equipo de música con buen sonido.',
    image: '/images/IMG_2378.webp',
  },
  {
    icon: <WifiRoundedIcon />,
    title: 'Wifi',
    description: 'Conexión a internet inalámbrica.',
    image: '',
  },
  {
    icon: <UmbrellaRoundedIcon />,
    title: '3 sombrillas de 150 x 150 cm',
    description: 'Sombrillas amplias para sombra.',
    image: '/images/IMG_2366.webp',
  },
  {
    icon: <NaturePeopleRoundedIcon />,
    title: 'Juego de sillones para jardín',
    description: 'Sillones cómodos para el jardín.',
    image: '/images/IMG_2179.webp',
  },
  {
    icon: <PanToolRoundedIcon />,
    title: 'Hamaca paraguaya',
    description: 'Hamaca paraguaya para relajarse.',
    image: '',
  },
  {
    icon: <TvRoundedIcon />,
    title: 'Televisor LCD 40"',
    description: 'Televisor con Netflix, YouTube, Disney y Movistar TV.',
    image: '/images/IMG_2376.webp',
  },
  {
    icon: <BedRoundedIcon />,
    title: 'Sofá cama',
    description: 'Sofá que se convierte en cama.',
    image: '/images/IMG_2186.webp',
  },
  {
    icon: <PowerRoundedIcon />,
    title: 'Grupo electrógeno',
    description: 'Generador eléctrico de respaldo.',
    image: '',
  },
  {
    icon: <AirRoundedIcon />,
    title: 'Ventilador',
    description: 'Ventilador para refrescar el ambiente.',
    image: '',
  },
  {
    icon: <ChairRoundedIcon />,
    title: '9 sillones directorio 3 mesas (2mts)',
    description: 'Sillones cómodos con mesas grandes.',
    image: '',
  },
  {
    icon: <ChildCareRoundedIcon />,
    title: 'Juegos para niños',
    description: 'Mangruyo, tobogán y hamaca para niños.',
    image: '/images/IMG_2180.webp',
  },
  {
    icon: <SportsSoccerRoundedIcon />,
    title: 'Juegos para adultos',
    description: 'Metegol, mesa de ping pong y dardos.',
    image: '/images/IMG_2181.webp',
  },
];

export default function Features() {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const itemsWithImages = items.filter(item => item.image); // Filtrar solo items con imagen
  const itemsPerPage = 3;
  const totalPages = Math.ceil(itemsWithImages.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const startIndex = currentPage * itemsPerPage;
  const paginatedItems = itemsWithImages.slice(startIndex, startIndex + itemsPerPage);

  // Simular carga con un delay
  React.useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container id="features" sx={{ py: { xs: 6, sm: 12 } }}>
      <Box sx={{ px: { xs: 2, sm: 4 }, py: 4,  }}> {/* Padding horizontal y vertical para mejor espaciado */}
        <Typography
          component="h2"
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: { xs: 2, sm: 3, md: 4 }, // Incremento del margen inferior para mejor separación
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.25rem' }, // Ajuste de tamaño de fuente
            lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 }, // Ajuste de interlineado para mejor legibilidad
            wordBreak: 'break-word', // Permite el ajuste de palabras largas
          }}
        >
          Características del servicio
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            mb: { xs: 2, sm: 4 }, // Margen inferior para separar del contenido superior
            textAlign: 'center',
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Ajuste del tamaño de fuente
            lineHeight: { xs: 1.5, sm: 1.6, md: 1.7 }, // Ajuste de interlineado para mejorar la lectura
            wordBreak: 'break-word', // Ajuste de palabras largas
          }}
        >
          Descubre las comodidades y servicios que ofrecemos para tu estancia en nuestro espacio. Desde un amplio quincho con parrilla hasta juegos para niños y adultos, cada detalle ha sido cuidadosamente diseñado para brindarte una experiencia memorable.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            order: { xs: 2, md: 1 },
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%', // Asegura que el Grid ocupe toda la altura
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{ flexGrow: 1 }} // Permite que el Stack crezca y ocupe el espacio disponible
          >
            {loading ? (
              Array.from({ length: itemsPerPage }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width="100%"
                  height={144}
                  sx={{ borderRadius: 1 }}
                />
              ))
            ) : (
              paginatedItems.map(({ icon, title, description, image }, index) => (
                <Card
                  key={startIndex + index}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleItemClick(startIndex + index)}
                  sx={{
                    p: 2,
                    height: '144px',
                    width: '100%',
                    background: 'none',
                    borderColor: (theme) =>
                      theme.palette.mode === 'light' ? 'grey.200' : 'grey.800',
                    borderWidth: selectedItem === startIndex + index ? 2 : 1,
                    borderColor:
                      selectedItem === startIndex + index
                        ? 'primary.main'
                        : 'grey.200',
                    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      borderColor: 'primary.main',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      textAlign: 'left',
                      flexDirection: { xs: 'row' },
                      alignItems: { xs: 'flex-start', sm: 'center' },
                      gap: { xs: 1, sm: 2 },
                    }}
                  >
                    <Box
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === 'light' ? 'grey.600' : 'grey.300',
                        fontSize: { xs: 28, sm: 36, md: 40 },
                        lineHeight: 1,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                      }}
                    >
                      {icon}
                    </Box>
                    <Box>
                      <Typography
                        color="text.primary"
                        variant="body1"
                        fontWeight="bold"
                        sx={{
                          textTransform: 'none',
                          fontSize: { xs: 16, sm: 18 },
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{
                          textTransform: 'none',
                          fontSize: { xs: 14, sm: 16 },
                        }}
                      >
                        {description}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              ))
            )}
          </Stack>
          
          {/* Contenedor de paginación */}
          <Box
            sx={{
              mt: 'auto', // Empuja el Box de paginación hacia abajo
              backgroundColor: 'background.paper',
              py: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handlePrevPage}
              startIcon={<ArrowBackIosNewRoundedIcon />}
              disabled={currentPage === 0}
              sx={{ textTransform: 'none' }}
            >
              Anterior
            </Button>
            <Button
              onClick={handleNextPage}
              endIcon={<ArrowForwardIosRoundedIcon />}
              disabled={currentPage === totalPages - 1}
              sx={{ textTransform: 'none' }}
            >
              Siguiente
            </Button>
          </Box>
        </Grid>
        {loading ? (
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height='100%'
              sx={{
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Grid>
        ) : (
        <Grid item xs={12} md={6} sx={{
          order: { xs: 1, md: 2 }, 
        }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { sm: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              boxShadow: (theme) =>
                theme.palette.mode === 'light'
                  ? '0px 4px 20px rgba(0, 0, 0, 0.1)'
                  : '0px 4px 20px rgba(0, 0, 0, 0.4)',
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: { xs: 300, sm: 400, md: 500 }, // Ajusta el alto según el tamaño de pantalla
                borderRadius: 2,
                overflow: 'hidden',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: 2,
                  background: 'rgba(0, 0, 0, 0.3)', // Overlay opcional para mejor visibilidad
                  zIndex: 1,
                },
                zIndex: 0,
                transition: 'background-image 0.5s ease-in-out', // Animación para el cambio de imagen de fondo
              }}
            >
              {itemsWithImages[selectedItem]?.image ? (
                <img
                  src={itemsWithImages[selectedItem]?.image}
                  alt={itemsWithImages[selectedItem]?.title}
                  style={{
                    width: '100%',
                    height: '100%', // Asegúrate de que la imagen llene el contenedor
                    objectFit: 'cover', // Ajusta la imagen para cubrir el contenedor sin distorsionar
                    borderRadius: 'inherit',
                    display: 'block',
                    position: 'relative',
                    zIndex: 0,
                  }}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: 2 }}
                />
              )}
            </Box>
          </Card>
        </Grid>
        )}
      </Grid>
    </Container>
  );  
}
import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

export default function LetrasLogo(props) {
  const theme = useTheme();
  const fillColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000'; 
  return (
    <SvgIcon {...props}>
        <svg version="1.1" viewBox="0 0 2048 297" width="1280" height="1280" xmlns="http://www.w3.org/2000/svg">
            <path transform="translate(1544,49)" d="m0 0h19l9 3 9 9 10 15 9 16 5 9 5-1 8-16 10-16 9-11 7-6 8-2h14l8 1 2 2v5l-4 6v155l4 6 3 1v-151l6-10 2-1 5 4 1 6-4 7v147l4 10v8l-2 2-4 1h-26l-8-2-4-5v-2l34-1-1-7-3-3h-3l-2 3-7 1h-23l-6-2-1-1v-5l4-6v-113l-5 3-5 8-5 11-4 13-3 19-2 4-2 1h-17l-4-4-2-7-3-19-8-18-5-8-3-3-2 8v104l5 7 2 1v-92l4 2 2 7 3 2 1 83 4 11v7l-3 2-5 1h-24l-7-2-4-5v-2l34-1-2-6-1-4h-6v3l-8 1h-22l-7-2-1-6 4-6v-156l-4-6 1-5z" fill={fillColor}/>
            <path transform="translate(1103,49)" d="m0 0h28l13 1 3 3-1 5-1 2v8l11 57 18 85 4 10v2l6 1-11-48-15-69-6-25v-9l3-6 4-2 3 4 1 4-1 3v9l18 85 10 46 5 13 5 10-1 7-3 2-5 1h-26l-8-2-3-4v-3l33-1 2-1-1-5-4-6h-2l-3 5-7 1h-24l-5-2-2-3 1-4 2-3-1-12-10-48-2-11-3-4-3-1h-11l-7 2-4 11-12 58 1 6 2 3v6l-2 1-9 1h-19l-12-2-1 5-4 10-9 3h-69l-7-2-4-4v-3l82-1 3-10 4-15 4-1 3 3 1 4 3 1 3-10 15-70 14-67 1-10-2-5 1-7 1-1zm14 42-3 6-3 13v8l6 2 7-1 1-2-1-11-4-15zm-59 129-3 5v2h6v-7z" fill={fillColor}/>
            <path transform="translate(427,49)" d="m0 0h43l7 2 1 1v6l-1 2v10l8 43 20 95 4 11 2 3 4 1-9-42-19-86-3-10-1-9 3-9 2-3h3l3 5-1 12 9 43 19 88 4 13 6 11v8l-4 3-5 1h-27l-7-3-2-3v-3l32-1 3-1-2-6-3-5h-2l-3 5-7 1h-23l-7-2-1-1v-6l3-7-13-64-2-7-5-1h-10l-8 3-3 9-12 57v6l3 6-1 5-9 2h-22l-7-2-1-1 1-7 5-10 8-37 19-89 3-15v-9l-2-9 3-3zm21 42-4 13-2 8v5l2 2 9 1 4-4-3-18-3-7z" fill={fillColor}/>
            <path transform="translate(1925,49)" d="m0 0h33l12 1 3 3-1 5-1 3v8l15 75 13 61 6 20v5l-1 1-7 1h-23l-5-2-2-3 1-4 2-3-1-11-11-53-2-1-2 5-8 2-2 13-10 46v8l4 10-1 5-3 3-5 1h-26l-7-3-3-3v-3l33-1 1-3-3-6-4-1-2 3-7 1h-22l-7-1-2-3 1-6 4-6 5-20 12-57 15-71-1-7-1-2v-6l3-3zm18 42-3 6-3 13v8l4 2h6l4-3-1-11-3-12-2-3zm1 52-10 2-4 6-11 51-2 9v9l4 5h2l3-13 11-50 3-7 3-2h14l-1-6-3-3z" fill={fillColor}/>
            <path transform="translate(288,49)" d="m0 0h40l17 1 12 5 7 6 6 10 3 11-1 13-4 12-8 11-9 7-8 4-11 3-26 3-3 2-1 3v78l3 4 3-1v-27l1-49 4-3 24-3 12-4 12-7 10-10 7-14 2-7v-24l4 4 3 7 1 5v9l-4 16-7 12-11 11-12 7-15 4-21 3v71l2 11 3 6v5l-4 3-4 1h-24l-9-3-2-2v-4l33-1-3-9h-4l-2 2-7 1h-23l-6-1-2-6 4-6v-158l-4-6 3-5zm23 26-8 2-2 2-1 15 2 10 3 2 5 1 14-1 9-4 5-6 2-5-1-8-4-5-5-2-6-1z" fill={fillColor}/>
            <path transform="translate(1444,47)" d="m0 0h17l11 3 10 6 10 10 8 14 6 18 3 17 1 8v33l-3 20-4 15-6 15-8 11-4 5-10 7-10 4-16 2-13-2-10-4-9-7-8-9-8-15-5-16-3-16-1-17v-25l2-17 5-19 8-16 8-11 11-8 9-4zm2 29-8 4-7 8-5 12-4 21v42l3 16 5 13 6 8 6 4 3 1h13l8-5 6-7 5-14 3-18v-41l-3-19-5-13-8-9-8-3z" fill={fillColor}/>
            <path transform="translate(780,47)" d="m0 0h17l13 4 9 6 8 8 8 13 7 21 3 18 1 13v18l-2 22-4 17-6 16-6 10-9 10-10 7-13 4h-20l-10-3-10-6-10-10-8-14-5-14-4-20-1-10v-36l3-20 5-16 7-14 8-10 12-9 11-4zm2 29-8 4-5 5-6 12-3 11-2 13v44l5 21 6 11 7 6 4 2h13l8-4 6-7 5-12 3-15 1-10v-33l-2-17-5-16-6-12-3-2-8-1z" fill={fillColor}/>
            <path transform="translate(595,47)" d="m0 0h20l12 3 6 4 3 3v7l-7 21-5 3h-2l3 5 5 3 5-8 8-21 2-1 4 4 1 6-6 17-6 12-4 3-5-2-8-7-8-6-2-1h-11l-7 4-6 9-4 12-1 7v40l4 18v7l-5-4-6-13-3-16v-26l2-15 4-13 5-9 4-5 10-5h14l5 2-2-5-8-3h-9l-11 4-8 7-8 14-4 14-2 13v23l3 15 5 13 6 10 7 7 12 6 10 2h9l9-3 11-8 5 1 1 1v8l-7 22-7 6-14 3h-11l-17-3-16-8-11-9-11-14-8-16-5-17-2-14v-25l2-16 5-18 8-16 8-11 7-7 14-9 12-4z" fill={fillColor}/>
            <path transform="translate(27,49)" d="m0 0h85l7 2 1 1-1 9-5 15-4 5-7-1-7-3h-33l-5 2-2 7v10l2 7 6 2h10l7-2 4-3 5 1 2 3v30l-1 3-6-1-8-3-6-1h-8l-7 3-1 5v58l3 5 1 1 17 1h26l8-1 6-4 7 1 1 5-6 20-2 4-7 2h-80l-7-2-1-6 4-6v-156l-4-6 1-5z" fill={fillColor}/>
            <path transform="translate(1791,49)" d="m0 0h64l9 1 3 3v25l-2 5 5 4 4 1 1-22 2-2 6 3v29l-2 2-7-1-8-3-12-1-4 1-1 4v127l3 9 2 5v5l-4 3-3 1h-27l-7-2-4-5v-2l35-1-3-9-6-1v3l-10 1h-21l-6-2-1-1 1-7 3-5 1-127-13 6h-6l-4-3 1-5 8-5h7l3 4 4 1-3-9-4-2h-9l-8 3-6 4-4-1-1-2v-28l3-3zm53 26-9 2-4 5v137l4 6h5l1-136 4-4 12-2 1-2-4-4z" fill={fillColor}/>
            <path transform="translate(187,47)" d="m0 0h22l14 3 6 4 3 3v8l-5 16-3 3h-5l-6-7-8-3h-11l-9 3-4 4-1 3v9l3 6 9 9 14 10 11 9 9 9 9 14 4 10 2 9v18l-3 12-6 12-9 10-7 6-11 5-8 2h-21l-14-3-12-5-6-4-1-2v-11l5-19 3-3h5l4 5 3 6 6 4 6 2h15l10-5 6-7 3-7 1-7-2-13-5-9-7-8-30-23-6-5-8-11-4-8-1-5v-14l3-10 6-9 7-7 14-7z" fill={fillColor}/>
            <path transform="translate(1292,49)" d="m0 0h20l5 3 1 3-5 9v135l4 4 6 1-2-5-1-1v-123l5-10 4-1 5 6-2 5-2 4-1 120-3 5 33-1 7-3h5l3 3-1 9-6 17-2 2-6 1h-73l-7-2-1-5 4-7v-156l-4-6 1-5 1-1z" fill={fillColor}/>
            <path transform="translate(958,49)" d="m0 0h18l9 2v6l-3 4-1 4v133l3 5h43l7-3 6 1 2 2-1 8-6 18-2 2-8 1h-72l-6-2-1-1 1-7 3-5v-154l-4-7 1-5 1-1z" fill={fillColor}/>
            <path transform="translate(1710,49)" d="m0 0h21l12 1 2 6-5 8v154l4 6v6l-2 1-12 1h-18l-5-1-1-7 4-7v-154l-4-7 1-6z" fill={fillColor}/>
            <path transform="translate(676,49)" d="m0 0h11l12 1 2 2v5l-4 6-1 8v145l5 9-1 5-1 1-9 1h-21l-5-1-2-3 1-5 3-5 1-21-1-135-4-7 2-5z" fill={fillColor}/>
            <path transform="translate(1876,64)" d="m0 0 7 3v29l-2 2-7-1-8-3-12-1-4 1-1 4v127l3 9 2 5v5l-4 3-3 1h-27l-7-2-4-5v-2l35-1-3-9-6-1v-3h5v-136l5-5 12-2v-3l6 2 7 6 4 1 1-22z" fill={fillColor}/>
            <path transform="translate(380,73)" d="m0 0 4 4 3 7 1 5v9l-4 16-7 12-11 11-12 7-15 4-21 3v71l2 11 3 6v5l-4 3-4 1h-24l-9-3-2-2v-4l33-1-3-9h-4-1l-1-7 4-1v-27l1-49 4-3 24-3 12-4 12-7 10-10 7-14 2-7z" fill={fillColor}/>
            <path transform="translate(1686,63)" d="m0 0 5 3 2 3-1 6-3 5v147l4 10v8l-2 2-4 1h-26l-8-2-4-5v-2l34-1-1-7-3-3h-4l1-4 3 1v-151l6-10z" fill={fillColor}/>
            <path transform="translate(1504,71)" d="m0 0 3 1 7 10 7 14 4 16 1 11v37l-2 16-4 17-8 19-9 14-10 11-11 7-9 4-11 2h-17l-12-3-10-5-5-4-8-11h3l14 9 10 4 5 1h19l16-5 11-8 3-1 2-4 7-9 8-16 6-20 3-17 1-13v-18l-2-21-5-19-6-16z" fill={fillColor}/>
            <path transform="translate(840,70)" d="m0 0 5 5 7 11 5 11 4 17 1 17v19l-1 19-4 20-7 19-8 14-10 12-12 9-12 5-11 2h-17l-11-3-10-5-9-8-4-6 1-3 10 8 12 6 9 2h18l12-3 11-6 10-9 8-11 6-12 6-19 3-15 1-8v-41l-4-20-6-18z" fill={fillColor}/>
            <path transform="translate(711,63)" d="m0 0 5 3 2 6-4 8v147l4 11v7l-2 2-4 1h-27l-8-4-2-5 33-1-1-7-2-2-1-4v-138l1-16z" fill={fillColor}/>
            <path transform="translate(1754,63)" d="m0 0 5 3 2 3-1 6-3 7v144l5 13v5l-4 3-3 1h-27l-8-4-1-5 34-1-5-11v-155z" fill={fillColor}/>
            <path transform="translate(1157,64)" d="m0 0 4 2 2 6-1 3v9l18 85 10 46 5 13 5 10-1 7-3 2-5 1h-26l-8-2-3-4v-3l33-1 2-1-1-5-4-6h-4l-1-4 5 1-11-48-15-69-6-25v-9l3-6z" fill={fillColor}/>
            <path transform="translate(488,64)" d="m0 0h3l3 5-1 12 9 43 19 88 4 13 6 11v8l-4 3-5 1h-27l-7-3-2-3v-3l32-1 3-1-2-6-3-5h-2l-2 2-1-5h4l-9-42-19-86-3-10-1-9 3-9z" fill={fillColor}/>
            <path transform="translate(1982,64)" d="m0 0 4 1 3 5-1 5v9l7 34 16 74 7 30 7 14 1 5-3 5-7 2h-26l-7-2-3-7 32-1 3-1-3-11-3-8-4-15-27-122v-9z" fill={fillColor}/>
            <path transform="translate(644,66)" d="m0 0 4 2 2 4-1 8-9 22-4 5-4 1-8-6-9-8-4-2h-11l-7 4-6 9-4 12-1 7v40l4 18v7l-5-4-6-13-3-16v-26l2-15 4-13 5-9 4-5 10-5h14l5 2 1-4 7 6-2 1 3 5 5 3 5-8 8-21z" fill={fillColor}/>
            <path transform="translate(239,138)" d="m0 0 4 2 7 9 5 10 3 14v15l-3 14-8 16-7 9-10 10-14 8-13 4-6 1h-20l-13-3-9-6-2-6 5 1 13 4 6 1h20l16-5 9-5 10-8 8-10 5-10 4-16v-19l-4-16z" fill={fillColor}/>
            <path transform="translate(1326,64)" d="m0 0 4 1 4 5-2 5-2 4-1 120-3 5 33-1 5-1-1 2-3 1h-44l-3-2 1-2 3 2 6 1-2-5-1-1v-123l5-10z" fill={fillColor}/>
            <path transform="translate(1957,149)" d="m0 0 2 3-1 4-2-1-2 5-8 2-2 13-10 46v8l4 10-1 5-3 3-5 1h-26l-7-3-3-3v-3l33-1 1-3-3-6-5-1-1-6 5 3 3-17 10-46 3-7 4-3h8l6 1z" fill={fillColor}/>
            <path transform="translate(1578,133)" d="m0 0 4 2 2 7 3 2 1 83 4 11v7l-3 2-5 1h-24l-7-2-4-5v-2l34-1-2-6-1-4h-6l-1-6 5 3z" fill={fillColor}/>
            <path transform="translate(450,152)" d="m0 0 9 1 1 1v6l-7 2-4 15-9 43v10l3 6v8l-3 3-6 1h-25l-8-3-3-4v-2l35-1-1-5-3-5 1-11 11-52 3-9 3-3z" fill={fillColor}/>
            <path transform="translate(1116,153)" d="m0 0h12l1 6-5 2h-3l-4 19-9 42v7l4 9-1 7-3 2-5 1h-26l-7-2-4-5v-2l34-1 1-4-3-5v-9l11-52 3-11z" fill={fillColor}/>
            <path transform="translate(994,64)" d="m0 0 4 1 4 5-2 5-2 4-1 118-1 1h-6l-2-1v-123z" fill={fillColor}/>
            <path transform="translate(130,212)" d="m0 0 4 1 3 3-1 8-5 15-4 7-6 2h-74l-10-2-4-5v-2l85-1 5-5z" fill={fillColor}/>
            <path transform="translate(101,114)" d="m0 0 4 2 4 6v26l-3 7-2 2-6-1-8-5-4-1h-9l-4 1-1 46-5 2-3-2v-50l5-5 6-1h12l10 4 1-1 1-9 1-20z" fill={fillColor}/>
            <path transform="translate(1048,212)" d="m0 0 4 1 2 2 1 4 3 1-3 10h-3l-1 5-4 10-9 3h-69l-7-2-4-4v-3l82-1 3-10 4-15z" fill={fillColor}/>
            <path transform="translate(1380,212)" d="m0 0 4 1 3 5-3 15-4 10-4 4-6 1h-69l-7-2-4-5v-2l82-1 3-10z" fill={fillColor}/>
            <path transform="translate(650,204)" d="m0 0 4 2 2 4-1 9-5 17-4 6-6 4-12 3-7 1h-20l-12-2-13-5-10-7-8-8-3-5 1-2 10 7 14 8 12 4 6 1h22l13-3 5-3 4-8 6-18z" fill={fillColor}/>
            <path transform="translate(1451,83)" d="m0 0h9l6 4-1 3-2 1-9 1-6 4-5 10-3 18-1 14v15l2 21 3 15 1 9-4-2-5-8-4-15-1-10v-36l2-17 4-13 5-8 6-5z" fill={fillColor}/>
            <path transform="translate(787,83)" d="m0 0h8l4 3v5l-9 1-6 4-3 4-3 9-3 24v27l3 22 4 16-4-1-6-8-4-15-1-9v-42l2-14 4-13 6-9z" fill={fillColor}/>
            <path transform="translate(127,60)" d="m0 0 4 2 3 6-6 24-5 6-4 1-7-3-4-2-35-1-1 7-1 1h-6l-2-4v-7l4-5 7-1h24l10 1 6 3 4-1z" fill={fillColor}/>
            <path transform="translate(239,65)" d="m0 0 4 2 2 2v7l-6 23-4 6-1 1-7-1-10-10-7-3h-10l-4 2-4 7-4-2-1-2v-7l5-5 5-2h14l9 4 8 7 3 1 3-11 4-17z" fill={fillColor}/>
            <path transform="translate(1634,128)" d="m0 0 3 1v24l-4 22-5 5h-13l-6-3-2-4 1-1 16-1 5-30 3-10z" fill={fillColor}/>
            <path transform="translate(1787,75)" d="m0 0h10l5 4 1 3v137l-3 2 1-4 1-127-13 6h-6l-4-3 1-5 8-5h7l3 4 4 1-3-9-4-2-8-1z" fill={fillColor}/>
            <path transform="translate(1680,72)" d="m0 0h1v156h-6l1-4 3 1v-151z" fill={fillColor}/>
            <path transform="translate(319,85)" d="m0 0h10l4 2 1 3-1 2-15 1-1 8-5 1-2-2v-10l4-4z" fill={fillColor}/>
            <path transform="translate(1788,83)" d="m0 0h7l2 2v7l-8 4h-6l-4-3 1-5z" fill={fillColor}/>
            <path transform="translate(1594,64)" d="m0 0 7 6 5 5 5 6 1 7-1 2-5-1-9-16-4-8z" fill={fillColor}/>
            <path transform="translate(1840,89)" d="m0 0h1v139h-6v-3h5z" fill={fillColor}/>
            <path transform="translate(1571,103)" d="m0 0 4 1 4 4-1 2-5-5-2 8v104l2 5-3-1-1-2v-112z" fill={fillColor}/>
            <path transform="translate(309,145)" d="m0 0h1v83l-5 1-1-7 4-1v-27z" fill={fillColor}/>
            <path transform="translate(1578,133)" d="m0 0h1l1 95h-6l-1-6 5 3z" fill={fillColor}/>
            <path transform="translate(124,231)" d="m0 0 1 3-3 5-2 1h-87v-1l85-1 5-5z" fill={fillColor}/>
            <path transform="translate(1323,200)" d="m0 0 4 1-1 3 33-1 5-1-1 2-3 1h-44l-3-2 1-2 3 2 6 1z" fill={fillColor}/>
            <path transform="translate(616,81)" d="m0 0 8 6-2 1 3 5 5 3 3-2-2 4-4-1-8-7-6-4 3-1z" fill={fillColor}/>
            <path transform="translate(311,230)" d="m0 0h2l2 5-1 5h-34v-1l33-1z" fill={fillColor}/>
            <path transform="translate(1053,219)" d="m0 0 5 1-3 10h-3l-1 5-1-4z" fill={fillColor}/>
            <path transform="translate(1927,232)" d="m0 0 2 1 1 5-3 2h-32v-1l31-1 1-3z" fill={fillColor}/>
            <path transform="translate(100,115)" d="m0 0h1v27l-2 5-4-1 3-2 1-9z" fill={fillColor}/>
            <path transform="translate(757,56)" d="m0 0 2 1-13 13v-3l6-7z" fill={fillColor}/>
            <path transform="translate(1857,79)" d="m0 0 6 2 4 5-9-2z" fill={fillColor}/>
            <path transform="translate(1421,56)" d="m0 0 2 1-12 12v-3l5-6z" fill={fillColor}/>
        </svg>
    </SvgIcon>
  );
}
import React from 'react';
import { Tabs, Tab } from '@mui/material';
import PromotionsIcon from '@mui/icons-material/LocalOffer'; // Asegúrate de que este icono esté disponible o importe el correcto

export default function PricingTabs({ tabValue, handleTabChange, theme }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        sx={{
          mb: 4,
          '& .MuiTabs-flexContainer': {
            borderBottom: `2px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          },
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 'bold',
            minWidth: 120,
            borderRadius: '4px',
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '& .Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.action.selected,
          },
        }}
      >
        <Tab label="Precios" />
          <Tab
            label="Promoción"
            icon={<PromotionsIcon />}
            iconPosition="start"
            sx={{
              fontWeight: 'bold',
              color: tabValue === 1 ? theme.palette.primary.main : theme.palette.text.secondary,
              '&.Mui-selected': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.action.selected,
              },
            }}
          />
      </Tabs>
    </div>
  );
}